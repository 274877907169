import { IBlock } from '../../../../framework/src/IBlock'
import { Message } from '../../../../framework/src/Message'
import { BlockComponent } from '../../../../framework/src/BlockComponent'
import MessageEnum, { getName } from '../Messages/MessageEnum'
import { runEngine } from '../../../../framework/src/RunEngine'

// Customizable Area Start
import { withLoaderProps } from '../HOC/withLoader.Web'
import { withToastProps } from '../HOC/withSnackBar.Web'
import { RouterProps } from 'react-router'
import { parseExpireTokenResponse, logoutAndRedirectToLoginPage } from '../Utilities/Utilities'
import { getStorageData, setStorageData } from '../../../../framework/src/Utilities'

// Customizable Area End

export const configJSON = require('./config')

export type Props = RouterProps &
  withToastProps &
  withLoaderProps & {
    id: string
    // Customizable Area Start
    // Customizable Area End
  }

interface S {
  // Customizable Area Start
  token: string
  tierType: string
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  id: any
  // Customizable Area End
}

export default class NavController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  getCurrentPlanApiCallId: string = ''
  // Customizable Area End

  constructor(props: Props) {
    super(props)
    this.receive = this.receive.bind(this)

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      getName(MessageEnum.AlertMessage),
    ]
    this.state = {
      token: '',
      tierType: "",
    }
    // Customizable Area End

    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages)
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage))

      const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage))

      const errorReponse = message.getData(getName(MessageEnum.RestAPIResponceErrorMessage))
      if (parseExpireTokenResponse(responseJson, this.state, this.props)) {
        if (apiRequestCallId != null) {
          if (apiRequestCallId === this.getCurrentPlanApiCallId) {
            // this.props.hideLoader()
            if (responseJson?.build_card_type) {
              const tierType = responseJson?.tier_type
              this.setState({ tierType })
              setStorageData('tierType', tierType)
            } else {
              this.parseApiErrorResponse(responseJson)
            }
          }
        }
      } else {
        logoutAndRedirectToLoginPage(this.props)
      }
    } else if (getName(MessageEnum.AlertMessage) === message.id) {
      const title = message.getData(getName(MessageEnum.AlertTitleMessage))
      const AlertBodyMessage = message.getData(getName(MessageEnum.AlertBodyMessage))

      if (AlertBodyMessage === 'State Not Found') return
			else {
				this.props.showToast({
					type: 'error',
					message: AlertBodyMessage,
				});
			}
    }
    // Customizable Area End
  }

  async getCurrentPlan() {
    if (this.state.tierType !== '') {
      return
    }
    // this.props.showLoader()
    const headers = {
      token:await getStorageData('admintoken'),
      apptoken: configJSON.getCurrentPlanAppToken || 'iCxBWGyfgL89NPCwkc-i'
    }

    const getCurrentPlanData = new Message(getName(MessageEnum.RestAPIRequestMessage))
    this.getCurrentPlanApiCallId = getCurrentPlanData.messageId
    const apiWithMarker = configJSON.getCurrentPlanAPI;
    apiWithMarker[1] = await getStorageData("build_card_id")

    getCurrentPlanData.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      apiWithMarker.join("")
    )

    getCurrentPlanData.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(headers)
    )
    getCurrentPlanData.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getCurrentPlanMethodType
    )
    runEngine.sendMessage(getCurrentPlanData.id, getCurrentPlanData)
  }
}
