// Customizable Area Start
// @ts-nocheck
import * as React from "react";
import { useEffect, useState, useRef } from "react";
import { EventEmitter } from 'eventemitter3';
import Nav from "../Nav";
import FadeInDropdown from "../Dropdown/FadeInDropdown/FadeInDropdown";
import { RoundIconBadge } from "../Badge/RoundIconBadge/RoundIconBadge";
import { Scrollbars } from "react-custom-scrollbars";
import { Button, Box } from "@material-ui/core";
import RefreshIcon from '@material-ui/icons/Refresh';
import CircularProgressBar from "../Charts/CircularProgressBar";
import "./assets/css/style.css";
const Logo: any = require("./assets/images/temp/logo.png");
import { getStorageData, setStorageData } from "../../../../framework/src/Utilities";
import actionCable from 'actioncable';
const baseURL = require("../../../../framework/src/config.js").baseURL;
const webSocketURL = baseURL.replace("https:", "wss:");
import { useRunEngine } from "../../../utilities/src/hooks/useRunEngine";
import { useBlockHelpers } from "../../../utilities/src/hooks/useBlockHelpers";
import MessageEnum, {getName} from "../../../../framework/src/Messages/MessageEnum";
import { Message } from "../../../../framework/src/Message";
import { defineUserRefiner } from "../../../../../packages/components/src/Refiner";

const audio = require("./assets/audio/notification.mp3");
export const configJSON = require("./config");
export const emitter = new EventEmitter();

interface LayoutProps {
  children: React.ReactNode;
  hideLayout: boolean
}

const subscribedMessages = [
  MessageEnum.RestAPIResponceMessage,
  MessageEnum.SessionResponseMessage,
];

export const Layout: React.FunctionComponent<LayoutProps> = (props) => {
  const [hideNav, setHideNav] = useState(false);
  const [adminUser, setAdminUser] = useState({});
  const [progress, setProgress] = useState(0);
  const [noti, setNoti] = useState(0);
  const [storeName, setStoreName] = useState("Store Name");
  const [lastRefresh, setLastRefresh] = useState(null);
  const [isAudioNotify, setIsAudioNotify] = useState(true);
  const playAudio = new Audio(audio) ;

  const getNewOrderApiCallId = useRef("");
  const getAudioDataApiCallId = useRef("");
  const setAudioDataApiCallId = useRef("");
  const {
    setReceiveCallback,
    sendBlockMessage,
    subscribe,
    unsubscribeFromMessage,
    sendNetworkRequest
  } = useRunEngine();

  const { extractNetworkResponse } = useBlockHelpers();

  const receive = (from: string, message: Message) => {
   if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const { apiRequestCallId, responseJson } =
        extractNetworkResponse(message);
        if(apiRequestCallId === getNewOrderApiCallId.current){
          updateOrderNotification(responseJson?.new_order_count)
        }
        if(apiRequestCallId === getAudioDataApiCallId.current){
          setIsAudioNotify(responseJson?.data?.order_notification_audio)
        }
        if(apiRequestCallId === setAudioDataApiCallId.current){
          setIsAudioNotify(responseJson?.data?.order_notification_audio)
        }
    }
  };

  const updateOrderNotification = (count:number) => {
    setNoti(count)
    const currentTime = new Date();
    const options = { day: '2-digit', month: 'long' };
    const formattedDate = currentTime.toLocaleDateString('en-US', options);
    const formattedTime = currentTime.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
    const lastRefreshString = `Last updated on ${formattedDate.split(' ')[1]} ${formattedDate.split(' ')[0]} at ${formattedTime}`;
    setLastRefresh(lastRefreshString); 
    setStorageData("lastRefresh", lastRefreshString);
  }
  const getCachedValues = async () => {
    setStoreName(await getStorageData("store_name") || "Store Name")
    const adminSaved = JSON.parse((await getStorageData("adminuser") || "{}"))
    setAdminUser(adminSaved);

    defineUserRefiner({
      id: adminSaved?.data?.attributes?.id,
      email: adminSaved?.data?.attributes?.email,
      name: adminSaved?.data?.attributes?.name,
    })
    const progress = await getStorageData("progress");
    setProgress(progress ? JSON.parse(progress) : 0);
  };

  const getNewOrderData = async () => {
    const headers = {
      "Content-Type": configJSON.jsonContentType,
      token: await getStorageData('admintoken'),
    };

    if (window.location.pathname !== "/admin/login" && window.location.pathname !== "/AdminOtherIntegrations") {
      sendNetworkRequest(
          getNewOrderApiCallId,
          configJSON.validationApiMethodType,
          configJSON.newOrderApiEndPoint,
          headers
      );
    }
  }
  const getAudioData = async () => {
    const headers = {
      "Content-Type": configJSON.jsonContentType,
      token: await getStorageData('admintoken'),
    };
    if (window.location.pathname !== "/admin/login" && window.location.pathname !== "/AdminOtherIntegrations") {
      sendNetworkRequest(
          getAudioDataApiCallId,
          configJSON.validationApiMethodType,
          configJSON.getAudioDataApiEndPoint,
          headers
      );
    }
  }

  const setAudioData = async () => {
    const headers = {
      "Content-Type": configJSON.jsonContentType,
      token: await getStorageData('admintoken'),
    };

    sendNetworkRequest(
        setAudioDataApiCallId,
        configJSON.validationApiPutMethodType,
        `${configJSON.setAudioDataApiEndPoint}${!isAudioNotify}`,
        headers
    );
  }
  const handleAudioToggle = () => {
    setAudioData();
  };

  const getLastRefreshDateTime = async() => {
    const storedDateTime = await getStorageData('lastRefresh');
    if (storedDateTime) {
      setLastRefresh(storedDateTime);
    }
  };

  useEffect(() => {
    getCachedValues();
    handleOpenDrawer();
    getLastRefreshDateTime();
    getAudioData();
    subscribedMessages.forEach((message) => subscribe(message));
    setReceiveCallback(receive)
    async function checkStorageData() {
      const admin = JSON.parse(await getStorageData("adminuser"));
      const progressData = await getStorageData("progress");
      if (admin) {
        setAdminUser(admin);
      }
      if (progressData) {
        setProgress(progressData);
      }
    }
    window.addEventListener("progressInserted", checkStorageData);
    window.addEventListener("resize", handleOpenDrawer)
    
    const cable = actionCable.createConsumer(`${webSocketURL}/cable`);
    cable.subscriptions.create('OrderNotificationsChannel', {
      received: function(data) {
        updateOrderNotification(data.count);
        if(data?.new_order && isAudioNotify){
          playAudio.play();
        }
      }
    });
    emitter.on("changeNotiNumber", (data) => {
      updateOrderNotification(data);
    });
    return () => {
      window.removeEventListener("resize", handleOpenDrawer)
      window.removeEventListener("progressInserted", checkStorageData);
      cable.subscriptions.subscriptions.forEach(sub => sub.unsubscribe());
      subscribedMessages.forEach((message) => unsubscribeFromMessage(message));
      emitter.off("changeNotiNumber", (data) => {
        setNoti(data)
      });
    };

    
  }, [isAudioNotify]);

  useEffect(() => {
    getNewOrderData();
  },[])

  const handleOpenDrawer = () => {
    if (window.document.body.clientWidth < 700) {
      setHideNav(true)
    } else {
      setHideNav(false)
    }
  }


  const gotoOrders = () => {
    const gotoOrders: Message = new Message(getName(MessageEnum.NavigationMessage));
    gotoOrders.addData(getName(MessageEnum.NavigationTargetMessage), "Orders");
    gotoOrders.addData(getName(MessageEnum.NavigationPropsMessage), props.children.props);
    sendBlockMessage(gotoOrders);
  }

  const newOrderNotification = (isVisible: boolean, className: string, audioDisabled:boolean) => {
    if(isVisible) {
      return (
        <Box >
          <div
            style={webStyle.flex}
            className={className}
          >
            <Box display={'flex'} onClick={gotoOrders}>
              <span style={webStyle.count}>{noti}</span>
              <div style={webStyle.lastRefreshDiv}>
                <span style={webStyle.notiText}>{configJSON.notiText}</span>
                <div>
                    {lastRefresh && <span style={webStyle.lastRefresh}>{lastRefresh}</span>}
                </div>
              </div>
            </Box>
            <div style={webStyle.notificationActionButtonDiv}>
              <Button onClick={getNewOrderData}
              style={webStyle.refreshButton}
              > 
                <RefreshIcon style={webStyle.refreshIcon}/>
              </Button>
              <Button onClick={handleAudioToggle}
              style={webStyle.audioButton}
              >
                {
                  audioDisabled ? 
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M3.025 4.20418C2.7 3.87918 2.7 3.35418 3.025 3.02918C3.35833 2.70418 3.88333 2.70418 4.20833 3.02918L16.9667 15.7958C17.2917 16.1208 17.2917 16.6458 16.9667 16.9708C16.6417 17.2958 16.1167 17.2958 15.7917 16.9708L14.675 15.8542C14.1167 16.3042 13.4917 16.6708 12.825 16.9458C12.275 17.1708 11.6667 16.7875 11.6667 16.1875C11.6667 15.8625 11.85 15.5458 12.15 15.4208C12.6333 15.2292 13.075 14.9708 13.4833 14.6625L10 11.1792V14.6542C10 15.3958 9.1 15.7708 8.575 15.2458L5.83333 12.5042H3.33333C2.875 12.5042 2.5 12.1292 2.5 11.6708V8.33751C2.5 7.87918 2.875 7.50418 3.33333 7.50418H5.83333L6.075 7.25418L3.025 4.20418ZM15.4917 11.9542C15.7083 11.3458 15.8333 10.6875 15.8333 10.0042C15.8333 7.55418 14.3167 5.45418 12.175 4.58751C11.875 4.47085 11.6667 4.19585 11.6667 3.87918V3.72085C11.6667 3.19585 12.1917 2.81251 12.6833 3.00418C15.5 4.07918 17.5 6.81251 17.5 10.0042C17.5 11.1625 17.2333 12.2542 16.7667 13.2292L15.4917 11.9542ZM8.575 4.76251L8.43333 4.90418L10 6.47085V5.34585C10 4.60418 9.1 4.23751 8.575 4.76251ZM11.6667 6.64585C12.9 7.26251 13.75 8.52918 13.75 10.0042C13.75 10.0708 13.7417 10.1375 13.7333 10.2042L11.6667 8.13751V6.64585Z" fill="#0F172A"/>
                  </svg>
                  :
                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M11.6667 3.87651V3.70984C11.6667 3.18484 12.1917 2.80984 12.675 3.00151C15.5 4.07651 17.5 6.80151 17.5 10.0015C17.5 13.2015 15.5 15.9265 12.675 17.0015C12.1917 17.1848 11.6667 16.8182 11.6667 16.2932V16.1265C11.6667 15.8098 11.8667 15.5348 12.1667 15.4182C14.3167 14.5598 15.8333 12.4515 15.8333 10.0015C15.8333 7.55151 14.3167 5.44317 12.1667 4.58484C11.875 4.46817 11.6667 4.19317 11.6667 3.87651ZM2.5 8.33484V11.6682C2.5 12.1265 2.875 12.5015 3.33333 12.5015H5.83333L8.575 15.2432C9.1 15.7682 10 15.3932 10 14.6515V5.34317C10 4.60151 9.1 4.22651 8.575 4.75151L5.83333 7.5015H3.33333C2.875 7.5015 2.5 7.87651 2.5 8.33484ZM13.75 10.0015C13.75 8.52651 12.9 7.25984 11.6667 6.64317V13.3515C12.9 12.7432 13.75 11.4765 13.75 10.0015Z" fill="#0F172A"/>
                </svg>
                }
                
              </Button>
            </div>
          </div>
        </Box>
      )
    } else return <></>
  }

  const { children, hideLayout } = props;
  if (hideLayout) {
    return <div className="admin-layout__content">{children}</div>;
  }
  let menuList = [
    {
      label: "Managing Orders",
      iconEl: <RoundIconBadge iconName="orders" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475072-managing-orders",
    },
    {
      label: "Setting and Managing inventory",
      iconEl: <RoundIconBadge iconName="list" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475081-setting-and-managing-inventory",
    },
    {
      label: "Setting up store and emails",
      iconEl: <RoundIconBadge iconName="wallet" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475108-setting-up-store-and-emails",
    },
    {
      label: "Configuring business settings",
      iconEl: <RoundIconBadge iconName="settings" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475115-configuring-business-settings",
    },
    {
      label: "Payments and Shipping",
      iconEl: <RoundIconBadge iconName="products" />,
      externalUrl:
        "https://intercom.help/engineerai/en/articles/6475121-payments-and-shipping",
    },
  ];
  let profileList = [
    {
      label: "Account Overview",
      iconName: "user",
      internalUrl: "/AdminAccount",
    },
    {
      label: "Logout",
      showRightArrow: false,
      iconName: "logout",
    },
  ];
  return (
    <div className={`admin-layout ${hideNav ? "admin-layout--hide-nav" : ""}`}>
      <div className="admin-layout__sidebar">
        <div className="admin-layout__brand-logo">
          <img className="img-fluid" src={Logo} alt="logo" />
        </div>
        <Scrollbars>
          <div className="admin-layout__link-container">
            <Nav />
          </div>
        </Scrollbars>
      </div>
      <div className="admin-layout__main">
        <div className="admin-layout__nav">
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center">
              <Button
                className="admin-layout__hamburger"
                style={webStyle.hamburgerButton}
                onClick={(e) => {
                  e.preventDefault();
                  setHideNav(!hideNav);
                }}
              >
                <i className="icon-menu f-18"></i>
              </Button>
              <span className="f-14 fw-normal text-capitalize" style={{ marginLeft: "4px"}}>{storeName}</span>
            </div>
            <div className="d-flex align-items-center" >
              {newOrderNotification(noti > 0, "order-notification-view-desktop", !isAudioNotify)}
              <FadeInDropdown
                className="me-10"
                menuClassName="setup-guide-menu"
                menuLists={menuList}
                label="Setup guide"
                badgeEl={<CircularProgressBar percentage={progress} />}
                menuLabel={"Setup guide"}
              />
              <FadeInDropdown
                menuLists={profileList}
                label={`Hi, ${adminUser?.data?.attributes?.name || "Admin User"}`}
                menuHeader={
                  <div className="">
                    <h6 className="f-14 mb-0 text-blue-grey-10">{`${adminUser
                      ?.data?.attributes?.name || "Admin User"}`}</h6>
                    <p className="f-10 mb-0 text-blue-grey-8">{`${adminUser?.data
                      ?.attributes?.email || "Admin@User.com"}`}</p>
                  </div> 
                }
                badgeName="sj"
                menuLabel={"Setup guide"}
              />
            </div>
          </div>
          {newOrderNotification(noti > 0, "order-notification-view-mobile", !isAudioNotify)}
        </div>
        <div className="admin-layout__content">
          {children}
        </div>
      </div>
    </div>
  );
};

const webStyle = {
  flex: {
    backgroundColor: "#FEF3C7",
    gap: "8px",
    borderRadius: "100px",
    marginRight: "16px",
    alignItems: "center",
    justifyContent:'space-between'
  } as React.CSSProperties,
  lastRefreshDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft:8,
    cursor:'pointer'
  },
  hamburgerButton:{minWidth:32},
  notificationActionButtonDiv:{display:"flex", alignItems:'center', justifyContent:'flex-end'},
  lastRefresh: {
    fontFamily: "Rubik",
    fontSize: "10px",
    color: "#64748B",
    fontFamily: "Rubik",
  },
  count: {
    padding: "7px 16px",
    color: "#fff",
    fontFamily: "Rubik",
    fontSize: "14px",
    fontWeight: 500,
    lineHeight: 1.4,
    borderRadius: "16px",
    backgroundColor: "#F59E0B",
    margin: "auto",
  } as React.CSSProperties,
  notiText: {
    fontFamily: "Rubik",
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 500,
    whiteSpace:'nowrap'
  } as React.CSSProperties,
  refreshButton: {
    display: "flex",
    alignItems: "center",
    minWidth:20,
    width:20,
    height:20
  } as React.CSSProperties,
  audioButton:{
    display: "flex",
    alignItems: "center",
    minWidth:20,
    height:20
  } as React.CSSProperties,
  refreshIcon: {
    color: "#0F172A",
    marginRight: "5px",
    height:20,
    width:20
  } as React.CSSProperties,
}
// Customizable Area End
