import firebase from 'firebase/app';
import 'firebase/analytics';

const firebaseConfig = {
  apiKey: "AIzaSyDl4lg7nXBtDmjMyFVtkVNQ1Bnif89_7Qo",
  authDomain: "ssrestaurantinternal.firebaseapp.com",
  projectId: "ssrestaurantinternal",
  storageBucket: "ssrestaurantinternal.appspot.com",
  messagingSenderId: "702182720864",
  appId: "1:702182720864:web:db8bfebf34c1ee43238a05",
  measurementId: "G-SVDE8KFQGF",
};

// Initialize Firebase
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
}
const analytics = firebase.analytics();

export { analytics };
