"use strict";


const baseURL = "__MARKER_FOR_BACKEND_URL_REPLACEMENT";

// const baseURL = "https://ssrestaurantinternal-393033-ruby.b393033.dev.eastus.az.svc.builder.cafe";

Object.defineProperty(exports, "__esModule", {
  value: true
});

exports.baseURL = baseURL; 
