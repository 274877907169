//@ts-nocheck
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';

const logo_icon = require("../assets/whiteLogo.png");
const error_icon = require("../assets/error.png");
const success_icon = require("../assets/success.png");

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        '& > div': {
            width: '100%',
            top: 0,
            right: 0,
            left: 0,
            transform: 'none',
            zIndex: 99999
        },
        '& > div > div': {
            width: '100%',
            borderRadius: "0px",
            height: "68px",
            background: "#3C3E49",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
        }
    },
}));

export default function AlertMessage(props: any) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={props.open} autoHideDuration={3000} onClose={props.close}>
                <Grid item lg={12} md={12} sm={12} style={{ display: "flex" }}>
                    <Grid item lg={2} md={2} sm={2} style={{ paddingLeft: "25px" }}><img src={logo_icon} alt="logo" /></Grid>
                    <Grid item lg={10} md={10} sm={10} style={{
                        fontFamily: "Rubik",
                        fontSize: "14px",
                        fontWeight: "400",
                        lineHeight: "20px",
                        color: "#FFFFFF",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}><img src={props.success ? success_icon : error_icon} alt="icon" /><span style={{ fontWeight: "600", marginLeft: "5px", marginRight: "2px" }}> {props.success ? 'Success! ' : 'Error! '}</span> {props.message}</Grid>
                </Grid>
            </Snackbar>
        </div >
    );
}
