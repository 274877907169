import TagManager from "react-gtm-module";

/**
 * Read some documentation here
 *
 * react-gtm-module
 * https://www.npmjs.com/package/react-gtm-module
 *
 * How to use it
 * https://analystadmin.medium.com/implementing-google-analytics-and-google-tag-manager-into-a-react-js-app-e986579cd0ee
 */

declare global {
  interface Window {
    dataLayer: any[];
  }
}

const isGtmLoaded = (): boolean => {
  let isLoaded = false;
  if (window?.dataLayer) {
    window.dataLayer = window.dataLayer || [];
    if (window.dataLayer.find((element: any) => element["gtm.start"])) {
      isLoaded = true;
    } else {
      isLoaded = false;
    }
  }
  return isLoaded;
};

/**
 *
 * @param gtmId: get this from API call and pass here to initialize
 */
export const initGTM = (gtmId: string) => {
  if (!isGtmLoaded()) {
    TagManager.initialize({
      gtmId,
      dataLayerName: "PageDataLayer",
    });
  }
};
/**
 *
 * @param tagManagerArgs
 * 
 * << Example 1 >>
 * sending events for pageview can look like 
    tagManagerArgs = {
        event: 'pageview',
        page: {
        url: location,
        title: title
        }
    }

 * << Example 2 >> 
 * sending some events
    tagManagerArgs = {
        event: 'event',
        eventProps: {
            category: category,
            action: action,
            label: label,
            value: value
        }
    }
 */
// eslint-disable-next-line no-shadow
export const sendToDataLayer = (tagManagerArgs: any) => {
  if (!isGtmLoaded()) {
    TagManager.dataLayer({
      dataLayer: {
        ...tagManagerArgs,
      },
      dataLayerName: "PageDataLayer",
    });
  }
};
