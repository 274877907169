// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "../../../framework/src/Utilities";

export const configJSON = require("./config");
import {
  isTokenExpired,
  clearStorageData,
} from "../../ss-cms-common-components/src/Utilities/Utilities";
import { emitter } from "../../../../packages/blocks/ss-cms-common-components/src/Layout/Layout";

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  classes: any;
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  variant: string;
  variants: any;
  options: any;
  title: string;
  type: string;
  isOpen: boolean;
  isEdit: boolean;
  isAddEdit: boolean;
  variantId: any;
  VariantName: string;
  setTouched: boolean;
  loading: boolean;
  loadingSearch: boolean;
  errorText: string;
  searchVariant: string;
  noSearchVariants: boolean;
  openModal: boolean;
  openAlert: boolean;
  alertSuccess: boolean;
  alertMessage: any;
  openSubmit: boolean;
  searchDropdown: string;
  dropdownFocus: boolean;
  dropDownSearchData: any;
  searchVariantModalOpen: boolean;
  searchVariants: any;
  searchSelectedVariant: any;
  pagination: Pagination;
  // Customizable Area End
}
// Customizable Area Start
export interface Pagination {
  current_page: number;
  next_page: number;
  prev_page: number;
  total_count: number;
  total_pages: number;
}
// Customizable Area End

interface SS {
  id: any;
}

export default class VariantsController extends BlockComponent<Props, S, SS> {
  // Customizable Area Start
  addVariantApiCallId: any;
  editVariantApiCallId: any;
  searchVariantApiCallId: any;
  searchVariantModalApiCallId: any;
  deleteVariantApiCallId: any;
  createVariantApiCall: any;
  updateVariantApiCall: any;
  optionCreateApiCall: any[] = [];
  variantId: any;
  getOrderNotificationId: string = ""
  mixPanelInfo : {buildCardId : number, emailId : string}= {buildCardId : 0, emailId: ""}
  // Customizable Area End
  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.SessionResponseMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      variant: "",
      dropdownFocus: false,
      variants: [],
      title: "",
      type: "single",
      options: [{ option_name: "", price: "", status: true }],
      isOpen: false,
      isEdit: false,
      isAddEdit: false,
      variantId: 0,
      VariantName: "",
      setTouched: false,
      loading: true,
      loadingSearch: false,
      errorText: "",
      searchVariant: "",
      noSearchVariants: false,
      openModal: false,
      openAlert: false,
      alertSuccess: false,
      alertMessage: "",
      openSubmit: false,
      searchDropdown: "",
      dropDownSearchData: [],
      searchVariants: [],
      searchVariantModalOpen: false,
      searchSelectedVariant: null,
      pagination: {
        current_page: 0,
        next_page: 0,
        prev_page: 0,
        total_count: 0,
        total_pages: 0,
      },
    };
    // Customizable Area End
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
  }

  async componentDidMount() {
    // Customizable Area Start
    this.searchVariant();
    this.getOrderNotification();
    this.mixPanelInfo = JSON.parse(await getStorageData("mixPanelInfo"))
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      if (isTokenExpired(message)) {
        return this.logoutAndNavigateLogin();
      }
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );

      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );

      let errorReponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson && !responseJson.error && !responseJson.errors) {
        this.apiRequestCall(apiRequestCallId, responseJson);
      } else if (
        apiRequestCallId === this.deleteVariantApiCallId &&
        responseJson.errors == "Variant associated with a product!"
      ) {
        this.searchVariant();
        this.setState({
          openModal: false,
          openAlert: true,
          alertSuccess: false,
          alertMessage:
            "Varinat could not be deleted as there is a live menu item associated to it.",
        });
      } else if (responseJson?.errors) {
        this.setState({ loading: false, loadingSearch: false });
        this.parseApiErrorResponse(responseJson);
        this.parseApiCatchErrorResponse(errorReponse);
      } else {
        this.setState({ loading: false, loadingSearch: false });
      }
    }
    // Customizable Area End
  }

  // Customizable Area Start
  getOrderNotification = async () => {
    const token = await getStorageData("admintoken");
    const header = {
      "Content-Type": "application/json",
      token
    };
    const requestSAMessage = new Message(getName(MessageEnum.RestAPIRequestMessage));
    this.getOrderNotificationId = requestSAMessage.messageId;
    requestSAMessage.addData(getName(MessageEnum.RestAPIResponceEndPointMessage), "/bx_block_order_management/orders/new_order_count");
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestHeaderMessage),JSON.stringify(header));
    requestSAMessage.addData(getName(MessageEnum.RestAPIRequestMethodMessage),"GET");
    runEngine.sendMessage(requestSAMessage.id, requestSAMessage);
  }

  apiRequestCall = (apiRequestCallId: any, responseJson: any) => {
    if (
      apiRequestCallId === this.searchVariantApiCallId &&
      !responseJson.message
    ) {
      this.setState({
        loading: false,
        loadingSearch: false,
        variants: responseJson.variants_with_options,
        pagination: responseJson?.metadata?.meta?.pagination,
        noSearchVariants: false,
      });
    } else if (
      apiRequestCallId === this.searchVariantApiCallId &&
      responseJson.message == "Variant Not Found"
    ) {
      this.setState({ loadingSearch: false, noSearchVariants: true });
    } else if (
      apiRequestCallId === this.deleteVariantApiCallId &&
      responseJson.message == "Destroyed!"
    ) {
      this.setState({
        openModal: false,
        openAlert: true,
        alertSuccess: true,
        alertMessage: "Variant deleted successfully.",
      });
      this.searchVariant();
    } else if (
      apiRequestCallId === this.createVariantApiCall &&
      responseJson.data?.id
    ) {
      this.setState({ variant: responseJson.data }, () => {
        this.createOptions(responseJson.data.id);
      });
    } else if (this.searchVariantModalApiCallId === apiRequestCallId) {
      this.setState({
        searchVariants: responseJson.variants_with_options || [],
        loadingSearch: false,
      });
    } else if (this.updateVariantApiCall === apiRequestCallId) {
    } else if (this.optionCreateApiCall.includes(apiRequestCallId)) {
      this.optionCreateCall(responseJson);
    } else if (apiRequestCallId == this.getOrderNotificationId) {
       emitter.emit("changeNotiNumber", responseJson.new_order_count)
    }
  };
  optionCreateCall = (responseJson: any) => {
    if (responseJson.data?.id) {
      this.addOptionsToVariant(responseJson.data?.id);
    }
  };
  addOptionsToVariant = (optionId: string, variantId: string = "") => {
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    const header = {
      "Content-Type": undefined,
    };
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      // @ts-ignore
      `bx_block_catalogue/catalogues_variants/add_options_to_variant?catalogue_variant_id=${variantId || this.variantId
      }&catalogue_option_id=${optionId}`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateOptionStatus = (optionIds: string[]) => {
    for (const optionId of optionIds) {
      const fullOption =
        this.state.options.find(
          (optionObj: any) => String(optionObj.id) === String(optionId)
        ) || {};
      if (fullOption) {
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
        const header = {
          "Content-Type": undefined,
        };
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          // @ts-ignore
          `bx_block_catalogue/catalogues_options/enable_disable?id=${optionId}&status=${fullOption.status}`
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.httpPutType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
      }
    }
  };

  createOptions = (variantId: string) => {
    const newOptions = this.state.options.filter((optionObj: any) => !optionObj.id);
    this.optionCreateApiCall = [];
    this.variantId = variantId;
    for (const optionIndex in newOptions) {
      const option = newOptions[optionIndex];
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      this.optionCreateApiCall.push(requestMessage.messageId);
      let formData = new FormData();
      formData.append("catalogue_variant_id", variantId);
      formData.append("option_name", option.option_name.trim());
      formData.append("price", option.price);
      const header = {
        "Content-Type": undefined,
      };
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        "bx_block_catalogue/catalogues_options"
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        formData
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPostType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    this.setState({ isOpen: false });
    this.searchVariant();
  };
  createVariant = (productId: string) => {
    let formData = new FormData();
    if (productId) {
      formData.append("catalogue_id", productId);
    }
    formData.append("variant_type", this.state.type);
    formData.append("title", this.state.title.trim());
    const header = {
      "Content-Type": undefined,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.createVariantApiCall = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      "/bx_block_catalogue/catalogues_variants"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestBodyMessage),
      formData
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPostType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  updateVariant = (variant: any, productId: any = null) => {
    const header = {
      "Content-Type": undefined,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.updateVariantApiCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      `/bx_block_catalogue/catalogues_variants/${variant?.id}?title=${this.state.title
      }&variant_type=${this.state.type}&${productId ? "catalogue_id=${productId}" : ""
      }`
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpPutType
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  deleteOptions = (options: any) => {
    for (const option of options) {
      const header = {
        "Content-Type": undefined,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_catalogue/catalogues_options/${option}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpDeleteType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };
  updateOptions = (options: any) => {
    for (const option of options) {
      const fullOption =
        this.state.options.find((optionObj: any) => optionObj.id === option) || {};
      const header = {
        "Content-Type": undefined,
      };
      const requestMessage = new Message(
        getName(MessageEnum.RestAPIRequestMessage)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIResponceEndPointMessage),
        `/bx_block_catalogue/catalogues_options/${fullOption.id}?option_name=${fullOption.option_name}&price=${fullOption.price}`
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestHeaderMessage),
        JSON.stringify(header)
      );
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestMethodMessage),
        configJSON.httpPutType
      );
      runEngine.sendMessage(requestMessage.id, requestMessage);
    }
  };

  handleAlertMessageClose = (event: any, reason: any) => {
    if (reason === "clickaway") {
      return;
    }
    this.setState({ openAlert: false });
  };

  searchVariantWithText = (VariantVal: any) => {
    if (VariantVal == "") {
      this.setState({ searchVariant: "", noSearchVariants: false });
      this.searchVariant();
    } else {
      this.setState({ searchVariant: VariantVal });
      this.searchVariant();
    }
  };

  searchVariant = async (isSearchModal: boolean = false, page = 1) => {
    let token = await getStorageData("admintoken");
    if (isSearchModal) {
      this.setState({ searchVariant: "" });
    }
    this.setState({ loadingSearch: true });
    const header = {
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    if (isSearchModal) {
      this.searchVariantModalApiCallId = requestMessage.messageId;
    } else {
      this.searchVariantApiCallId = requestMessage.messageId;
    }

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      isSearchModal
        ? configJSON.getVariantsAPIEndPoint + this.state.searchVariant
        : configJSON.getVariantsAPIEndPoint +
            this.state.searchVariant +
            "&page=" +
            page +
            "&per_page=10"
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpGetType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  handleClose = () => {
    this.setState({ openModal: false });
  };

  CreateVariantModalClose = (getVariant: boolean) => {
    if (getVariant) {
      this.searchVariant();
    }
    this.setState({
      isOpen: false,
      isEdit: false,
      searchSelectedVariant: null,
    });
  };

  showCreateSuccessPopup = () => {
    this.setState({
      openAlert: true,
      alertSuccess: true,
      alertMessage: "Variant Created Succesfully",
    });
  };

  showEditeSuccessPopup = () => {
    this.setState({
      openAlert: true,
      alertSuccess: true,
      alertMessage: "Variant Edited Succesfully",
    });
  };

  deleteVariant = async () => {
    let token = await getStorageData("admintoken");

    this.setState({ loading: true });
    const header = {
      "Content-Type": configJSON.VariantApiContentType,
      token,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );

    this.deleteVariantApiCallId = requestMessage.messageId;

    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.deleteVariantAPIEndPoint + this.state.variantId
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );

    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.httpDeleteType
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
  };
  logoutAndNavigateLogin = () => {
    clearStorageData();
    const navigateTo = new Message(getName(MessageEnum.NavigationMessage));
    navigateTo.addData(
      getName(MessageEnum.NavigationTargetMessage),
      "admin/login"
    );
    navigateTo.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(navigateTo.messageId, navigateTo);
  };

  handlePageChange = (page: number) => {
    this.searchVariant(false, page);
  };
  // Customizable Area End
}
// Customizable Area End
