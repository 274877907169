// Customizable Area Start
import * as React from 'react';
import { useState, useEffect } from 'react';
import { NavItem, NavLink, Collapse } from 'reactstrap';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { mixPanelEventHit } from "../../../../components/src/Mixpanel";
import { getStorageData } from "../../../../framework/src/Utilities";

interface RenderLinkProps extends RouteComponentProps {
    data: any
    tierType?: string
}

const RenderLink: any = withRouter((props: RenderLinkProps) => {
  const { data, location, tierType } = props;
  const haveSubLinks = Array.isArray(data.subLinks) && data.subLinks.length > 0;
  const isActive =
      data.link === "/"
          ? location.pathname === data.link
          : location.pathname.startsWith(data.link);

  const initialOpen = !haveSubLinks
      ? false
      : data.subLinks.some((i: any) => location.pathname.startsWith(i.link));
  const [isOpen, toggleOpen] = useState(initialOpen);

    useEffect(() => {
        if (haveSubLinks) {
            if (isActive) {
                toggleOpen(true)
            }
        }
    }, [])
    useEffect(() => {
        if (haveSubLinks) {
            const isActive = data?.subLinks?.map((link:any)=>{return link?.link}).includes(location.pathname);
            if(isActive){
                toggleOpen(true)
            }
        }
    }, [location])

    return (
        <NavItem className='admin-nav__item'>
            <NavLink
                href={data.link || '#'}
                className='admin-nav__link'
                onClick={
                    async (e) => {
                        e.preventDefault();
                        if (haveSubLinks) {
                            toggleOpen(!isOpen)
                        } else if (data.link) {
                            if(data.link === window.location.pathname) {
                                window.location.reload()
                            } else {
                                props.history.push(data.link)
                            }
                        }
                        mixPanelEventHit("Admin navigation",{buildCardId : JSON.parse(await getStorageData("mixPanelInfo"))?.buildCardId, emailId : JSON.parse(await getStorageData("mixPanelInfo"))?.emailId})
                    }
                }
                active={isActive}
            >
                <div className='d-inline-flex align-items-center'>
                    {data.iconName === 'icon-advertising-megaphone' ? 
                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M6.44201 10.9749C6.08148 11.1674 5.81237 11.4955 5.69406 11.8867C5.57574 12.2779 5.61794 12.7002 5.81134 13.0602H5.81534C6.21994 13.8129 7.15691 14.0969 7.91134 13.6956L8.14401 13.5696C12.1887 11.3909 12.846 11.6529 15.3333 11.4849L12.4293 6.03223C10.7633 8.22289 10.6993 8.65823 6.44201 10.9749Z" stroke="#676B7E" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7.66675 10.2964L9.66008 14.5157C10.0613 15.268 10.9961 15.5529 11.7487 15.1524" stroke="#676B7E" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M8.65674 6.83381V3.18848" stroke="#676B7E" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M2.65674 3.18848V7.83381C2.65674 8.3861 3.10445 8.83381 3.65674 8.83381H6.65674" stroke="#676B7E" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.6567 4.98823L6.32341 1.09023C5.94309 0.747917 5.36572 0.747917 4.98541 1.09023L0.656738 4.98823" stroke="#676B7E" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M9.65674 4.08816V1.3335" stroke="#676B7E" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    :
                    <i className={`${data.iconName} admin-nav__lin-icon`}></i>
                    }
                    &nbsp;
                    &nbsp;
                    <span>{data.label}</span>
                </div>
                {
                    haveSubLinks && (
                        <i className={`icon-chevron-${isOpen ? 'up' : 'down'} admin-nav__angle-icon f-12`}></i>
                    )
                }
            </NavLink>
            {
                haveSubLinks && (
                    <Collapse isOpen={isOpen} className="mt-3">
                        {
                            data.subLinks.map((subLinkData: any) => <RenderLink tierType={tierType} data={subLinkData} />)
                        }
                    </Collapse>
                )
            }
        </NavItem>
    );
})

export default RenderLink;
// Customizable Area End
