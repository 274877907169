import React, { useState, useEffect } from "react";
import { CsvDialog } from "../Dialog/CsvDialog";
import CsvUploadBox from "../UploadBox/CsvUploadBox.web";
import FormHelperText from "@material-ui/core/FormHelperText";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { useStyles } from "./ImportCsvUploadDialogStyles";
import { configJSON } from "../../../inventorymanagement/src/InventoryManagementController.web";

type Props = {
  onSubmit: () => void;
  open: boolean;
  setOpen: (newState: boolean) => void;
  onSampleDownload: () => void;
  onFileSelect: (files: any) => void;
  error: string;
  selectedCsvFile: any;
  showHint?: boolean;
};

export const ImportCsvUploadDialog = ({
  onSubmit,
  open,
  setOpen,
  onSampleDownload,
  onFileSelect,
  selectedCsvFile,
  error,
  showHint = true,
}: Props) => {
  const classes = useStyles();
  const [internalError, setInternalError] = useState(error);
  const [confirmDisabled, setConfirmDisabled] = useState(false);

  useEffect(() => {
    setInternalError(error);
    setConfirmDisabled(!!error);
  }, [error]);

  const clearForm = () => {
    onFileSelect(undefined);
    setInternalError('');
    setConfirmDisabled(false);
  };

  const handleOnSubmit = async () => {
    onSubmit();
  };

  return (
    <CsvDialog
      onClose={clearForm}
      onSubmit={handleOnSubmit}
      open={open}
      setOpen={setOpen}
      title={configJSON.titleImportMenu}
      containerClassName={classes.dialog}
      customContent
      width="684px"
      minHeight="540px"
      confirmDisabled={confirmDisabled || !selectedCsvFile}
      okClassName="csvuploadImportBtn"
    >
      <div className={classes.content}>
        {showHint && (
          <div className={classes.hint}>
            <span>{configJSON.csvDialogMessageText}</span>
          </div>
        )}
          {internalError && (
            <FormHelperText className={classes.errorMsgDiv} error={true}>
              {internalError}

            </FormHelperText>
          )}
        <div className={classes.uploadWrapper}>
          {selectedCsvFile  ? (
            <div className={classes.selectedCsvFileContainer}>
            <div className={classes.selectedFile}>
              <div className={classes.uploadWrapper1}>
                <span className={classes.selectedFileName}>
                  {selectedCsvFile.name}
                </span>
                <span className={classes.selectedFileSize}>
                  {selectedCsvFile.size > 1024 * 1024
                    ? `${Math.ceil(selectedCsvFile.size / 1024 / 1024)}mb`
                    : `${Math.ceil(selectedCsvFile.size / 1024)}kb`}
                </span>
              </div>
              <IconButton
                aria-label="close"
                className={classes.removeButton}
                onClick={clearForm}
              >
                <CloseIcon className={classes.closeIcon} />
              </IconButton>
              
            </div>
            <div className={classes.errorMsgDiv2}>

            {internalError && (
              <CsvUploadBox
              dropContainerStyle={{ marginTop:"40px" }}
              uploadIcon={require("./assets/add.svg")}
              height="200px"
              width="425px"
              onTouched={() => { }}
              sizeMsg="Max 20MB"
              uploadMsg={
                <p className={classes.uploadBoxMessage}>
                 {configJSON.toUpload}
                </p>
              }
              onSelectFile={onFileSelect}
              acceptedFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
              
              )}
              <div className={classes.downloadCsvBtnWrapper}>
                <button className={classes.actionBtn} onClick={onSampleDownload}>{configJSON.sampleCsvTemplate}</button>
                <a target="_blank" href="https://intercom.help/engineerai/en/articles/9345173-restaurant-troubleshooting-guide-csv-upload" className={classes.actionBtn}>{configJSON.needToImporting}</a>
              </div>
              </div>
            </div>
          ) : (
            <>
            <CsvUploadBox
              dropContainerStyle={{ marginTop: "20px" }}
              uploadIcon={require("./assets/add.svg")}
              height="200px"
              width="425px"
              onTouched={() => { }}
              sizeMsg="Max 20MB"
              uploadMsg={
                <p className={classes.uploadBoxMessage}>
                 {configJSON.toUpload}
                </p>
              }
              onSelectFile={onFileSelect}
              acceptedFormat=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
              />
             <div className={classes.downloadCsvBtnWrapper}>
                <button className={classes.actionBtn} onClick={onSampleDownload}>{configJSON.sampleCsvTemplate}</button>
                <a target="_blank" href="https://intercom.help/engineerai/en/articles/9345173-restaurant-troubleshooting-guide-csv-upload" className={classes.actionBtn}>{configJSON.needToImporting}</a>
              </div>
            </>
          )}
        </div>
      </div>
    </CsvDialog>
  );
};