import { Message } from "../../../../framework/src/Message";
import MessageEnum, {
  getName,
} from "../Messages/MessageEnum";

export const getResponseVariables = (message: Message) => {
  const apiRequestCallID = message.getData(
    getName(MessageEnum.RestAPIResponceDataMessage)
  );
  const responseJSON = message.getData(
    getName(MessageEnum.RestAPIResponceSuccessMessage)
  );
  return [responseJSON, apiRequestCallID];
};
