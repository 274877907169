// Customizable Area End
// @ts-nocheck
import React from "react";
import {
  Dialog,
  Tooltip,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
  Box,
  Switch,
  Button,
} from "@material-ui/core";
import { Formik, ErrorMessage, FieldArray, Form } from "formik";
import withStyles from "@material-ui/core/styles/withStyles";

export const configJSON = require("./config");
import { deleteIcon, questionIcon } from "./assets";
import AddNewVariantController from "./AddNewVariantController.web";
const CloseIcon = require("../../../components/assets/closeIcon.png");

// Customizable Area Start
import CloseIconMUI from '@material-ui/icons/Close';

export const ToBeStyledTooltip = ({ className, ...props }) => (
  <Tooltip {...props} classes={{ tooltip: className }} />
);
export const StyledTooltip = styled(ToBeStyledTooltip)(({ theme }) => ({
  backgroundColor: "#F6F0FF",
  borderRadius: "0.1875rem",
  fontFamily: "Inter",
  fontSize: "0.625rem",
  lineHeight: "0.9375rem",
  fontWeight: 400,
  color: "#475569",
  top: "1rem !important",
}));

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiSelect-select:focus": {
    backgroundColor: "white",
  },

  "& .MuiPaper-root": {
    width: "42.75rem",
    height: "51.3rem",
    maxWidth: "80%",
    maxHeight: "90vh",
    fontFamily: "Inter",
    overflowY: "hidden",
    userSelect: "none",
  },
  ".MuiTypography-root": {
    fontFamily: "Inter",
  },
}));

export const IOSSwitch = withStyles((theme) => ({
  track: {
    borderRadius: 26 / 2,
    border: "none",
    backgroundColor: "#C0C3CE",
    opacity: 1,
    transition: theme.transitions.create(["background-color", "border"]),
  },

  root: {
    width: 48,
    height: 26,
    padding: 0,
    margin: "8px 1.25rem",
  },
  checked: {},
  focusVisible: {},
  thumb: {
    width: 23,
    height: 22,
  },
  switchBase: {
    padding: 2,
    "&$checked": {
      transform: "translateX(20px)",
      color: "#fff",
      "& + $track": {
        backgroundColor: "#6200EA",
        opacity: 1,
        border: "none",
      },
    },
    "&$focusVisible $thumb": {
      color: "#red",
      border: "6px solid #fff",
    },
  },
}))(({ classes, ...props }) => {
  return (
    <Switch
      focusVisibleClassName={classes.focusVisible}
      disableRipple
      classes={{
        root: classes.root,
        switchBase: classes.switchBase,
        thumb: classes.thumb,
        track: classes.track,
        checked: classes.checked,
      }}
      {...props}
    />
  );
});
// Customizable Area End

// Customizable Area Start
export const Styles = (theme: Theme) => {
  return {
    // old style
    "& input, & textarea, & div": {
      fontFamily: "Inter",
      fontWeight: 400,
      lineHeight: "1.125rem",
      fontSize: "0.75rem",
    },
    select: {
      "& ul": {},
      "& li": {
        fontFamily: "Rubik",
        fontSize: "0.875rem",
        lineHeight: "1.375rem",
        fontWeight: 400,
      },
    },
    input: {
      "& fieldset": {
        top: "0rem",
      },
      "& input[type=number]::-webkit-outer-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
      "& input[type=number]": {
        "-moz-appearance": "textfield",
      },
      "& input[type=number]::-webkit-inner-spin-button": {
        "-webkit-appearance": "none",
        margin: 0,
      },
    },
    // new style
    "@global": {
      "*::-webkit-scrollbar": {
        width: "2px",
      },
      "::-webkit-scrollbar-track": {
        borderRadius: "5px",
        background: "#CBD5E1",
      },
      "::-webkit-scrollbar-thumb": {
        borderRadius: "5px",
        background: "#5500CC",
      },
    },
    
    modalCloseIcon: {
      width: "1.5rem",
      height: "1.5rem",
    },
    DialogHeaderText: {
      fontFamily: "Rubik",
      fontWeight: 500,
      fontSize: "26px",
      lineHeight: "30.81px",
      color: "#3C3E49",
    },
    
    fieldTitle: {
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontSize: "0.75rem",
      fontWeight: 500,
      lineHeight: "1.25rem",
    },
    titleInput: {
      marginTop: "0.75rem",
    },
    scrollContainer: {
      height: "100%",
      minHeigh: "100%",
      padding: "0.5rem 1.5rem 0rem",
      overflowY: "auto",
    },

    horigentleLine: {
      marginTop: "1.25rem",
      marginBottom: "0rem !important",
      border: "1px solid #E2E8F0",
    },
    typeSelectField: {
      height: "3rem",
      fontFamily: "Rubik",
      fontSize: "0.875rem",
      lineHeight: "1.5rem",
      fontWeight: 400,
    },
    textHint: {
      color: "#83889E",
      lineHeight: "1.25rem",
      fontSize: "0.75rem",
      fontWeight: 400,
    },
    activeSelectMenu: {
      backgroundColor: "#F6F0FF !important",
    },
    // table
    table_header_wrapper: {
      backgroundColor: "#F1F5F9",
      borderRadius: "0.1875rem 0.1875rem 0rem 0rem",
      height: "2.75rem",
    },
    table_body_wrapper: {
      border: "1px solid #E2E8F0",
      minHeight: "3rem",
      height: "auto",
    },
    tableBodyRowText: {
      margin: "0rem  1.25rem",
      fontFamily: "Rubik",
      color: "#3C3E49",
      fontWeight: 400,
      fontSize: "0.875rem;",
      lineHeight: "1.5rem",
    },
    tableHeaderText: {
      margin: "0rem  1.25rem",
      fontFamily: "Rubik",
      color: "#3C3E49",
      fontWeight: 500,
      fontSize: "0.75rem;",
      lineHeight: "1.25rem",
    },
    tableBodyRowInput: {
      margin: "0rem  1.25rem",
      width: "12.5rem",
      maxWidth: "70%",
    },
    tableBodyRow: {
      border: "1px solid #E2E8F0",
    },
    addOptionBtn: {
      width: "8.125rem",
      height: "2rem",
      background: "#00D659",
      borderRadius: "0.1875rem",
      fontFamily: "Inter",
      fontSize: "0.75rem",
      lineHeight: "1.125rem",
      fontWeight: 700,
    },
    tableBodyRowInputprice: {
      margin: "0rem  1.25rem",
      width: "6rem",
    },
    addOptionBtnHide: {
      display: "none",
    },
    
    btnRoot: {
      "&:hover": {
        background: "#00D659 !important",
      },
    },
    cancelBtn: {
      width: "7.375rem",
      height: "100%",
      color: "#3C3E49",
      fontFamily: "Rubik",
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: "1.25rem",
      textDecorationLine: "underline",
      background: "none",
    },
    saveBtn: {
      width: "8.375rem",
      height: "100%",
      background: "#00D659",
      borderRadius: "0.1875rem",
      fontFamily: "Rubik",
      fontSize: "1rem",
      lineHeight: "1.5rem",
      fontWeight: 500,
      color: "#3C3E49",
    },

    cancelBtnRoot: {
      "&:hover": {
        background: "none",
        textDecorationLine: "underline",
      },
    },
    minMaxTitle:{
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 700,
      lineHeight: '18px',
      color: '#3C3E49',
      marginBottom : '8px'
    },
    tooltipContainer:{
      fontFamily: "Rubik",
      fontSize: "12px",
      fontWeight: 400,
      lineHeight: "20px",
      width: '275px',
      height: '64px',
      padding: '12px 16px',
      boxShadow: '0px 8px 32px 0px #0000000F',
      backgroundColor: '#FFFFFF',
      color: '#64748B',
    },
    errorContainer:{
      height:'32px',
      marginLeft: '12px',
      borderLeft: '4px solid #DC2626',
      borderRadius: '5px',
      padding: '4px 16px 4px 16px',
      width:'327px',
      display:'flex',
      alignItems:'center',
      justifyContent:'space-between',
      backgroundColor: '#FEE2E2',
      marginTop: '22px'
    },
    closeErrorIcon:{
      fontSize:'16px',
      color:'#DC2626',
      cursor:'pointer'
    },
    errorText:{
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '18px',
      color: '#DC2626',
    },
  };
};
// Customizable Area End

export class AddEditVariant extends AddNewVariantController {
  // Customizable Area Start
  TableHeader = () => {
    const { classes } = this.props;
    const { isEditStatus } = this.state;
    return (
      <React.Fragment>
        <Box width="60%">
          <Typography
            component="h6"
            variant="h6"
            className={classes.tableHeaderText}
          >
            Option
          </Typography>
        </Box>
        <Box width="50%">
          <Typography
            component="h6"
            variant="h6"
            className={classes.tableHeaderText}
          >
            Price
          </Typography>
        </Box>
        <Box width="34%">
          <Typography
            component="h6"
            variant="h6"
            className={classes.tableHeaderText}
          >
            {isEditStatus ? "Enable" : "Delete"}
          </Typography>
        </Box>
      </React.Fragment>
    );
  };

  RenderInput = (
    index,
    option,
    handleBlur,
    handleChange,
    filedName,
    classname,
    { errors, touched }
  ) => {
    const { isEditStatus, optionsLength } = this.state;
    let { classes } = this.props;
    if (isEditStatus && index <= optionsLength - 1) {
      return (
        <Typography
          component="h6"
          variant="h6"
          className={classes.tableBodyRowText}
        >
          {option[filedName]}
        </Typography>
      );
    } else {
      return (
        <TextField
          className={`${classname}`}
          name={`options[${index}].${filedName}`}
          type={filedName === "option_name" ? "text" : "number"}
          placeholder={
            filedName === "option_name" ? "Add option" : "eg: 20, 30"
          }
          data-test-id="optionTestName"
          fullWidth
          value={option[filedName]}
          variant="outlined"
          onBlur={handleBlur}
          onChange={handleChange}
          onKeyDown={(event) => {
            event.key === "Enter" && event.preventDefault();
          }}
          size="small"
          error={Boolean(
            errors.options &&
              errors.options[index] &&
              touched.options &&
              touched.options[index] &&
              errors.options[index][filedName] &&
              touched.options[index][filedName] &&
              !option[filedName]
          )}
          helperText={
            Boolean(
              errors.options &&
                errors.options[index] &&
                touched.options &&
                touched.options[index] &&
                errors.options[index][filedName] &&
                touched.options[index][filedName] &&
                !option[filedName]
            ) && `${filedName == "option_name" ? "option" : filedName} required`
          }
          InputProps={{ inputProps: { min: 0 } }}
        />
      );
    }
  };

  TableBodyRowShowData = (
    arrayHelpers,
    values,
    handleBlur,
    handleChange,
    errors,
    touched
  ) => {
    const { classes } = this.props;
    const { isEditStatus } = this.state;
    return values.options.map((option, index) => {
      return (
        <Box
          width="100%"
          height="5rem"
          display="flex"
          justifyContent="space-between"
          className={classes.tableBodyRow}
          key={index}
          data-test-id="refTest"
          ref={(node) => (this.optionsRef.current[index] = node)}
        >
          <Box width="60%" style={{ marginTop: "1.18rem" }}>
            {this.RenderInput(
              index,
              option,
              handleBlur,
              handleChange,
              "option_name",
              `${classes.input} ${classes.tableBodyRowInput}`,
              { errors, touched }
            )}
          </Box>
          <Box width="50%" style={{ marginTop: "1.18rem" }}>
            {this.RenderInput(
              index,
              option,
              handleBlur,
              handleChange,
              "price",
              `${classes.input} ${classes.tableBodyRowInputprice}`,
              { errors, touched }
            )}
          </Box>
          <Box width="34%" alignSelf="center">
            {isEditStatus ? (
              <IOSSwitch
                name={`options[${index}].status`}
                data-test-id={`options[${index}].status`}
                checked={option.status}
                onChange={handleChange}
              />
            ) : (
              <img
                key={option.id}
                src={deleteIcon}
                data-testId="delete"
                onClick={() => {
                  if(values.options.length > 1){
                    arrayHelpers.remove(index);
                    this.updateValueMinMax(values)
                  }  

                }}
                alt="delete"
                style={{
                  width: 24,
                  height: 24,
                  cursor:
                    values.options.length === 1 ? "not-allowed" : "pointer",
                  marginLeft: "1.25rem",
                }}
              />
            )}
          </Box>
        </Box>
      );
    });
  };

  isFirefox = () =>
    navigator?.userAgent?.toLowerCase()?.indexOf("firefox") > -1;
  // Customizable Area End
  render() {
    const { classes } = this.props;
    return (
      // Customizable Area Start
      <BootstrapDialog
        onClose={this.props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={true}
        fullWidth
        maxWidth="md"
      >
        <Formik
          data-test-id="formik"
          initialValues={this.state.formInitialValues}
          onSubmit={(values) => this.variantFormSubmit(values)}
          validationSchema={this.formSchema}
          innerRef={this.formRef}
          enableReinitialize={true}
        >
          {(props) => {
            const { values, handleBlur, errors, touched, handleChange } = props;
            return (
              <Form
                translate={undefined}
                style={{ margin: "0 !important", height: "100%" }}
              >
                {/* sdfsdf */}
                <Box
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  width="100%"
                  padding="3rem 3rem 0rem"
                >
                  <Box display="flex" width="100%" alignItems="center">
                    <Box width="80%">
                      <Typography
                        component="h5"
                        className={classes.DialogHeaderText}
                      >
                        Variants
                      </Typography>
                    </Box>
                    <Box display="flex" width="20%" justifyContent="flex-end">
                      <img
                        data-test-id="closeIcon"
                        src={CloseIcon}
                        onClick={(event) => {
                          event.preventDefault();
                          this.props.handleClose();
                        }}
                        alt="close"
                        style={{
                          width: 24,
                          height: 24,
                          color: "#000000",
                          cursor: "pointer",
                          position: "relative",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box display="flex" width="100%" mt="0.31rem">
                    <Box width="100%">
                      <Typography className={`${classes.textHint}`}>
                        Setup additional customization that can be applied to
                        your menu Item.
                      </Typography>
                    </Box>
                  </Box>
                </Box>

                {/*  sdf*/}

                <Box
                  style={{ height: "calc(100% - 228px)", overflow: "hidden" }}
                  width="100%"
                  p="0.5rem 1.5rem 0rem"
                >
                  {/* overflow container */}
                  <Box
                    width="100%"
                    className={`${classes.scrollContainer} ${
                      this.isFirefox() ? "mozilaVariantMOdalScrollbar" : ""
                    }`}
                    ref={this.scrollContainerRef}
                  >
                    <Box
                      display="flex"
                      width="100%"
                      flexDirection="column"
                      mt="1.94rem"
                    >
                      {/* title field */}
                      <Box width="100%">
                        <Typography
                          component="h6"
                          variant="h6"
                          className={classes.fieldTitle}
                        >
                          Title *
                        </Typography>
                        {this.props.isInventoryAdd && (
                          <Typography className={`${classes.textHint}`}>
                            Search for existing
                          </Typography>
                        )}

                        <TextField
                          className={`${classes.input} ${classes.titleInput} `}
                          name="title"
                          type="text"
                          data-test-id="varientField"
                          fullWidth
                          placeholder="Title"
                          value={values.title}
                          variant="outlined"
                          onBlur={handleBlur}
                          onKeyDown={(event) => {
                            event.key === "Enter" && event.preventDefault();
                          }}
                          onChange={handleChange}
                          error={Boolean(errors.title && touched.title)}
                        />
                        <ErrorMessage name="title">
                          {(errorMsg) => (
                            <div style={{ color: "red", marginTop: "5px" }}>
                              {errorMsg}
                            </div>
                          )}
                        </ErrorMessage>
                      </Box>

                      <hr className={classes.horigentleLine} />
                      {/* select field */}
                      <Box width="100%" mt="1.25rem">
                        <Typography
                          component="h6"
                          variant="h6"
                          className={classes.fieldTitle}
                        >
                          Type *
                        </Typography>

                        <Box
                          display="flex"
                          alignItems="center"
                          width="50%"
                          mt="0.75rem"
                        >
                          <Box width="90%">
                            <Select
                              className={classes.typeSelectField}
                              fullWidth
                              variant="outlined"
                              disabled={
                                (this.state.isEditStatus &&
                                  this.state.isEdit) ||
                                this.state.isEdit
                              }
                              name="variant_type"
                              value={values.variant_type}
                              data-test-id="type"
                              onChange={handleChange}
                            >
                              {["single", "multiple"].map((option) => (
                                <MenuItem
                                  key={option}
                                  value={option}
                                  selected
                                  classes={{
                                    selected: classes.activeSelectMenu,
                                  }}
                                >
                                  {option[0].toUpperCase() + option.slice(1)}
                                </MenuItem>
                              ))}
                            </Select>
                          </Box>

                          <Box width="10%">
                            <StyledTooltip
                              placement="right"
                              style={{
                                fontFamily: "Inter",
                                fontSize: "10px",
                                fontWeight: 400,
                                lineHeight: "15px",
                                color: "#475569",
                              }}
                              title={
                                this.state.isEdit
                                  ? "Type field for Variant cannot be changed once created. Please create a new variant if needed."
                                  : "Select 'Single' if the customer can choose only one item from the list of options, or choose 'Multiple' if they can select multiple options."
                              }
                            >
                              <img
                                src={questionIcon}
                                alt=""
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginLeft: "0.5rem",
                                }}
                              />
                            </StyledTooltip>
                          </Box>
                        </Box>
                      </Box>

                      {/* Limit Choices */}

                      <Box width="100%" mt="1.25rem">
                        <Typography
                          component="h6"
                          variant="h6"
                          className={classes.fieldTitle}
                        >
                          Limit Choices  <StyledTooltip
                              placement="right"
                              className={classes.tooltipContainer}
                              title={"How many choices can a customer select from the options below?"}
                            >
                              <img
                                src={questionIcon}
                                alt=""
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginLeft: "0.5rem",
                                }}
                              />
                            </StyledTooltip>
                        </Typography>

                        <Box
                          display="flex"
                          
                          width="100%"
                          mt="0.75rem"
                        >
                          {/* min */}
                          <Box width='80px' display='flex' flexDirection='column'>
                            <Typography
                              component="h6"
                              variant="h6"
                              className={classes.minMaxTitle}
                            >
                              Minimum
                            </Typography>
                            <Select
                              className={classes.typeSelectField}
                              fullWidth
                              variant="outlined"
                              name="min"
                              value={values.min}
                              data-test-id="min"
                              onChange={(event)=>{
                                this.updateMinMaxFlag(true)
                                handleChange(event)
                              }}
                            >
                              {Array.apply(null, Array(values.options.length + 1)).map((emptyValue,option) => (
                                
                                <MenuItem
                                  key={option}
                                  value={option}
                                  selected
                                  classes={{
                                    selected: classes.activeSelectMenu,
                                  }}
                                >
                                 {option}
                                </MenuItem>
                              ))}
                            </Select>
                            <ErrorMessage name="min">
                              {(errorMsg) => (
                                <div style={{ color: "red", marginTop: "5px" }}>
                                  {errorMsg}
                                </div>
                              )}
                            </ErrorMessage>
                          </Box>

                            {/* max */}
                          {values.variant_type === 'multiple' &&
                            <>
                              <Box width='80px' display='flex' flexDirection='column' marginLeft='30px'>
                                <Typography
                                  component="h6"
                                  variant="h6"
                                  className={classes.minMaxTitle}
                                >
                                  Maximum
                                </Typography>
                                <Select
                                  className={classes.typeSelectField}
                                  fullWidth
                                  variant="outlined"
                                  name="max"
                                  value={values.max}
                                  data-test-id="max"
                                  onChange={(event)=>{
                                    this.updateMinMaxFlag(true)
                                    handleChange(event)
                                  }}
                                >
                                  {Array.apply(null, Array(values.options.length + 1)).map((emptyValue,option) => (
                                    <MenuItem
                                      key={option}
                                      value={option}
                                      selected
                                      classes={{
                                        selected: classes.activeSelectMenu,
                                      }}
                                    >
                                      {option}
                                    </MenuItem>
                                  ))}
                                </Select>
                                <ErrorMessage name="max">
                                  {(errorMsg) => (
                                    <div style={{ color: "red", marginTop: "5px" }}>
                                      {errorMsg}
                                    </div>
                                  )}
                                </ErrorMessage>
                              </Box>
                              {/* error */}
                            { values.min !==null && values.max !==null && values.max < values.min && this.state.minMaxErroFlag  ?
                              <Box width='327px' className={classes.errorContainer} data-test-id="erromessageBlock" >
                                {/* error text */}
                                <Typography
                                  component="h6"
                                  variant="h6"
                                  className={classes.errorText}
                                >
                                  Maximum cannot be lesser than minimum
                                </Typography>
                                {/* close icon */}
                                <CloseIconMUI className={classes.closeErrorIcon} data-test-id="closeErrorMessage" onClick={()=>{
                                  this.updateMinMaxFlag(false)
                                }} />
                              </Box>
                              : null
                            }
                            </>

                          }

                        </Box>
                      </Box>


                      {/*  */}


                      <hr className={classes.horigentleLine} />

                      {/* table */}
                      {/* header */}
                      <Box
                        width="100%"
                        height="2.75rem"
                        display="flex"
                        alignItems="center"
                        mt="1.25rem"
                        className={classes.table_header_wrapper}
                      >
                        {this.TableHeader()}
                      </Box>
                      {/* tableBody */}

                      <Box width="100%" className={classes.table_body_wrapper}>
                        <FieldArray
                          data-test-id="fieldArray"
                          name="options"
                          render={(arrayHelpers) => (
                            <React.Fragment>
                              {this.TableBodyRowShowData(
                                arrayHelpers,
                                values,
                                handleBlur,
                                handleChange,
                                errors,
                                touched,
                              )}
                              
                              <button
                                className={classes.addOptionBtnHide}
                                data-testId="addOptionBtn"
                                onClick={(event) =>
                                  this.AddOptionForm(event, arrayHelpers)
                                }
                                ref={this.addOPtionBtnRef}
                              >
                                Add new option
                              </button>
                            </React.Fragment>
                          )}
                        />
                      </Box>
                    </Box>
                    {this.RenderOptionsMessage(errors)}
                    {/* ------- */}
                  </Box>
                  {/* overflow end */}
                </Box>
                {/* sdfsdf */}
                <Box width="100%" mt="0.88rem">
                  <Box px="3rem">
                    <button data-test-id="update"
                      className={classes.addOptionBtnHide}
                      onClick={() => this.updateValueMinMax({
                        variant_type: 'multiple',
                        title: 'test',
                        options: ['1'],
                        min: 2,
                        max: 3
                      })}>update validation</button>
                    <Button
                      data-test-id="add_btn_option"
                      variant="contained"
                      className={classes.addOptionBtn}
                      classes={{ root: classes.btnRoot }}
                      style={{ textTransform: "none" }}
                      data-testId="addOptionBtn"
                      onClick={this.handleAddOption}
                    >
                      Add new option
                    </Button>
                  </Box>
                </Box>
                {/* sdsdfsdf */}

                {/* add option btn show */}
                <Box
                  width="100%"
                  height="4rem"
                  display="flex"
                  justifyContent="flex-end"
                  mt="1.31rem"
                  borderTop="1px solid #E2E8F0"
                  style={{ position: "absolute", bottom: "0px", left: "0px" }}
                >
                  <Button
                    className={classes.cancelBtn}
                    classes={{ root: classes.cancelBtnRoot }}
                    style={{ textTransform: "none" }}
                    data-test-id="cancelBtn"
                    onClick={(event) => {
                      event.preventDefault();
                      this.props.handleClose();
                    }}
                  >
                    Cancel
                  </Button>

                  <Button
                    variant="contained"
                    type="submit"
                    className={classes.saveBtn}
                    classes={{ root: classes.btnRoot }}
                    data-test-id="submitBtn"
                  >
                    Save
                  </Button>
                </Box>
              </Form>
            );
          }}
        </Formik>
      </BootstrapDialog>
      // Customizable Area End
    );
  }
}
export default withStyles(Styles)(AddEditVariant);
// Customizable Area End
