//@ts-nocheck
import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

const logo_icon = require("../assets/whiteLogo.png");

const useStyles = makeStyles((theme) => ({
    root: {
        '& > * + *': {
            marginTop: theme.spacing(2),
        },
        '& > div': {
            width: '100%',
            top: 0,
            right: 0,
            left: 0,
            transform: 'none',
        },
        '& > div > div': {
            width: '100%',
            borderRadius: "0px",
            height: "68px",
            background: "#3C3E49",
            justifyContent: "center",
            alignItems: "center",
            boxShadow: "none",
        }
    },
    discardButton: {
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: "400",
        lineHeight: "14px",
        color: "#FFFFFF",
        textTransform: "none",
    },
    submitButton: {
        fontFamily: "Rubik",
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "19px",
        padding: "10px, 26px",
        borderRadius: "4px",
        gap: "8px",
        color: "#334155",
        background: " #00D659",
        textTransform: "none",
        height: "44px",
        width: "144px"
    }
}));

export default function SubmitButtonPopup(props: any) {
    const classes = useStyles();
    return (
        <div className={classes.root}>
            <Snackbar anchorOrigin={{ vertical: 'top', horizontal: 'center' }} open={props.open}>
                <Grid item lg={12} md={12} sm={12} style={{ display: "flex" }}>
                    <Grid item lg={2} md={2} sm={2} style={{ paddingLeft: "25px" }}><img src={logo_icon} alt="logo" /></Grid>
                    <Grid item lg={10} md={10} sm={10} style={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "right",
                        justifyContent: "end",
                        paddingRight: "25px",
                    }}>
                        <Button className={classes.discardButton} style={{ background: "none", textDecoration: "underline" }} data-testId="discardBtn" onClick={() => props.close()}>
                            Discard Changes
                        </Button>
                        <Button
                            variant="contained"
                            className={classes.submitButton}
                            style={{ background: "#00D659", padding: "10px 26px" }}
                            data-testId="categorySubmit"
                            onClick={() => props.submit()}
                        >
                            Save Changes
                        </Button></Grid>
                </Grid>
            </Snackbar>
        </div >
    );
}
