// Customizable Area Start
import * as React from "react";
import { useState, useEffect, useRef } from "react";
import CircleBadge from "../../Badge/CircleBadge";
import { withRouter } from "react-router-dom";
import { RouterProps } from "react-router";
import "./assets/css/style.css";
import { getStorageData } from "../../../../../framework/src/Utilities";
import { clearStorageData } from "../../Utilities/Utilities";
import { mixPanelEventHit } from "../../../../../components/src/Mixpanel";

interface MenuListProps {
  label: string;
  iconName?: string;
  internalUrl?: string;
  externalUrl?: string;
  iconEl?: any;
  showRightArrow?: boolean;
}
export type FadeInDropdownProps = RouterProps & {
  className?: string;
  menuLists: MenuListProps[];
  menuHeader?: any;
  menuLabel?: string;
  label?: string;
  togglerHeader?: any;
  badgeName?: string;
  badgeEl?: any;
  menuClassName?: string;
};

const getInitials = (name: string) => {
  if (!name) return "";
  let rgx = new RegExp(/(\p{L}{1})\p{L}+/, "gu");

  // @ts-ignore
  let initials = [...name.matchAll(rgx)] || [];

  return (
    // @ts-ignore
    ((initials.shift()?.[1] || "") + (initials.pop()?.[1] || "")).toUpperCase()
  );
};

const FadeInDropdown: React.FunctionComponent<FadeInDropdownProps> = (
  props
) => {
  const [isOpen, setOpen] = useState(false);
  const [adminUser, setAdminUser] = useState<any>({});
  const isOpenRef = useRef(isOpen);
  const setCachedStorageData = async () => {
    const adminUser = await getStorageData("adminuser");
    if (adminUser) {
      setAdminUser(JSON.parse(adminUser).data);
    }
  };

  useEffect(() => {
    setCachedStorageData();
  }, []);
  useEffect(() => {
    isOpenRef.current = isOpen;
  }, [isOpen]);
  const {
    menuLists,
    menuHeader,
    label,
    badgeName,
    badgeEl,
    togglerHeader,
    menuLabel,
    className,
    history,
    menuClassName,
  } = props;

  const handleClickItem = async (e: any, listItem: MenuListProps) => {
    if (listItem.label === "Logout") {
      mixPanelEventHit("Logout",{buildCardId : JSON.parse(await getStorageData("mixPanelInfo"))?.buildCardId, emailId : JSON.parse(await getStorageData("mixPanelInfo"))?.emailId})
      clearStorageData();
      history?.push({
        pathname: "/admin/login",
        state: {
          currentPathName: props?.history?.location?.pathname,
          currentPathState: props?.history?.location?.state,
        },
      });
    }
    if (listItem?.internalUrl) {
      history?.push(listItem.internalUrl);
    }
    if (listItem.externalUrl) {
      window.open(listItem.externalUrl, "_blank");
    }
  };

  return (
    <div className={`${className} custom-dropdown-fade-in`}>
      <div className="custom-dropdown-fade-in__toggler">
        {!badgeEl ? (
          <CircleBadge
            label={getInitials(adminUser?.attributes?.name || "Admin User")}
          />
        ) : (
          badgeEl
        )}
        <span
          className="custom-dropdown-fade-in__toggler-header ms-2"
          onClick={(e) => {
            e.preventDefault();
            setOpen(!isOpen);
          }}
        >
          {!togglerHeader ? label : togglerHeader}
        </span>
        <a
          href="#"
          className="custom-dropdown-fade-in__toggler-arrow-icon"
          onClick={(e) => {
            e.preventDefault();
            setOpen(!isOpen);
          }}
        >
          <i className="icon-chevron-down f-10"></i>
        </a>
      </div>
      <div
        className={`custom-dropdown-fade-in__menu ${menuClassName} ${
          isOpen ? "active" : ""
        }`}
      >
        <div className="custom-dropdown-fade-in__menu-header">
          {!menuHeader ? menuLabel : menuHeader}
          <a
            href="#"
            className="custom-dropdown-fade-in__toggler-arrow-icon"
            onClick={(e) => {
              e.preventDefault();
              setOpen(!isOpen);
            }}
          >
            <i className="icon-cross"></i>
          </a>
        </div>
        <div className="custom-dropdown-fade-in__menu-body">
          <ul className="custom-dropdown-fade-in__menu-list list-unstyled mb-0">
            {menuLists.map((list: MenuListProps) => (
              <li
                key={list.label}
                className="custom-dropdown-fade-in__menu-item"
                value={list.label}
                onClick={(e: any) => handleClickItem(e, list)}
              >
                <div className="d-flex align-items-center">
                  <span className="custom-dropdown-fade-in__item-icon">
                    {!list.iconEl ? (
                      <i className={`icon-${list.iconName}`}></i>
                    ) : (
                      list.iconEl
                    )}
                  </span>
                  &nbsp; &nbsp;
                  {list.label}
                </div>
                {(!list.hasOwnProperty("showRightArrow") ||
                  (list.hasOwnProperty("showRightArrow") &&
                    list.showRightArrow !== false)) && (
                  <i className="icon-arrow-right"></i>
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {isOpen && (
        <div
          className="custom-dropdown-fade-in__backdrop"
          onClick={(e) => {
            setOpen(!isOpen);
          }}
        ></div>
      )}
    </div>
  );
  // Customizable Area End
};
// @ts-ignore
export default withRouter(FadeInDropdown);
// Customizable Area End
