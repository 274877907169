import _refiner from "refiner-js";

export const baseURL = require("../../framework/src/config.js").baseURL;
let isProduction = false;
if (baseURL.includes("ruby")) {
  //this is builder form url, so we can check for dev, stage, uat and prod
  isProduction = baseURL.includes(".prod.") && baseURL.includes("builder");
} else {
  isProduction = true;
}

if (isProduction) {
  _refiner("setProject", "acad7f30-270f-11ef-b31d-07b50603e296");
} else {
  _refiner("setProject", "acad7f30-270f-11ef-b31d-07b50603e296");
}

export const defineUserRefiner = ({
  id,
  email,
  name,
}: {
  id?: string | number;
  email?: string;
  name?: string;
}) => {
  _refiner("identifyUser", {
    id, // Replace with your user ID
    email, // Replace with user Email
    name, // Replace with user name
    website: window.location.href,
  });
};

export const showFormCancelCTARefiner = () => {
  if (isProduction) {
    _refiner("showForm", "42e48dd0-68f0-11ef-83b2-3991275f4db0");
  } else {
    _refiner("showForm", "5cda8790-2710-11ef-ba8a-6d793c7dcedf");
  }
};

export const showFormCSATRefiner = () => {
  if (isProduction) {
    _refiner("showForm", "1f7651a0-68f0-11ef-991c-0b129f1ea4c8");
  } else {
    _refiner("showForm", "21e4f6e0-2710-11ef-b87d-157177d4f11e");
  }
};
