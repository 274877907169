import mixpanel from "mixpanel-browser";

export const baseURL = require("../../framework/src/config.js").baseURL;
let trackAutomaticEvents = true;
if (baseURL.includes("dev") || baseURL.includes("stage")) {
  mixpanel.init("9876c6a5566bcfb79ca59f405e69121f");
} else {
  //do not use this id in NON Production mode
  trackAutomaticEvents = true;
  mixpanel.init("b00917cbecef55c2f06127f6891dfc0f");
}

let actions: any = {
  track: (name: any, data: any) => {
    if (trackAutomaticEvents) {
      // data ? analytics.track(name, data) : analytics.track(name);
      data ? mixpanel.track(name, data) : mixpanel.track(name);
    }
  },
};

export let AppMixpanel: any = actions;

export let mixPanelEventHit = async (eventName: string, data: any = {}) => {
  if (eventName !== "") {
    AppMixpanel.track(eventName, data);
  }
};
