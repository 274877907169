// Customizable Area Start
import React, { Component, Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

const EmailAccountLogin = lazy(() => import("../../emailaccountlogin/src/Login.web"));
const EmailAccountForgotPassword = lazy(() => import("../../emailaccountforgotpassword/src/ForgotPassword.web"));
const AdminEmailTemplates = lazy(() => import("../../adminemailtemplates/src/EmailTemplates.web"));
const AdminEmailTemplatesCreate = lazy(() => import("../../adminemailtemplates/src/EmailTemplatesCreate.web"));
const AdminStaticPage = lazy(() => import("../../adminstaticpage/src/StaticPageTable.web"));
const AdminStaticPageCreate = lazy(() => import("../../adminstaticpage/src/CreateStaticPage.web"));
const AdminImageCatalogue = lazy(() => import("../../adminimagecatalogue/src/ImageCatalogue.web"));
const AdminImageCatalogueBulkUpload = lazy(() => import("../../adminimagecataloguebulkupload/src/ImageCatalogueBulkUpload.web"));
const AdminEditImage = lazy(() => import("../../admineditimage/src/EditImage.web"));
const AdminCustomerFeedback = lazy(() => import("../../admincustomerfeedback/src/CustomerFeedback.web"));
const AdminCustomerFeedbackCreate = lazy(() => import("../../admincustomerfeedback/src/CustomerFeedbackCreate.web"));
const AdminStoreDetails = lazy(() => import("../../adminstoredetails/src/StoreDetails.web"));
const AdminTaxes = lazy(() => import("../../admintaxes/src/Taxes.web"));
const AdminShipping = lazy(() => import("../../adminshipping/src/Shipping.web"));
const AdminPromoCode = lazy(() => import("../../adminpromocode/src/PromoCode.web"));
const ShareStoreTab = lazy(() => import("../../adminpromocode/src/ShareStoreTab.web"));
const AdminPromoCodeCreate = lazy(() => import("../../adminpromocode/src/PromoCodeCreate.web"));
const AdminPaymentIntegration = lazy(() => import("../../adminpaymentintegration/src/Payment.web"));
const AdminMobileApp = lazy(() => import("../../adminmobileapp/src/MobileApp.web"));
const AdminNotifications = lazy(() => import("../../adminnotifications/src/Notifications.web"));
const OtherIntegrations = lazy(() => import("../../adminotherintegrations/src/OtherIntegrations.web"));
const AdminAccount = lazy(() => import("../../adminaccount/src/AccountDetails.web"));
const Variants = lazy(() => import("../../inventorymanagement/src/Variants.web"));
import InventoryManagement from "../../inventorymanagement/src/InventoryManagement.web";
import ItemsLibrary from "../../inventorymanagement/src/ItemsLibrary.web";
const Categories = lazy(() => import("../../categoriessubcategories/src/Categories.web"));
const OrderList = lazy(() => import("../../ordermanagement1/src/OrderList.web"));
const OrderPrint = lazy(() => import("../../ordermanagement1/src/OrderPrint.web"));
const Reviews = lazy(() => import("../../ordermanagement1/src/Reviews.web"));
const CustomerList = lazy(() => import("../../adminconsole2/src/CustomerList.web"));
const ReviewItemsFromLibrary = lazy(() => import("../../inventorymanagement/src/ReviewItemsFromLibrary.web"));
const ItemAvailabilityLayout = lazy(() => import("../../itemavailability/src/ItemAvailability.web"));
const AdminBranding = lazy(() => import("../../websitebuilder/src/Branding.web"));
const AdminMarketing = lazy(() => import("../../storecontentmanagement2/src/MarketingTab.web"));
const MarketingSignupForm = lazy(() => import("../../storecontentmanagement2/src/Branding.web"));
const AdminSiteMap = lazy(() => import('../../searchengineoptimisationseo/src/SiteMaps.web'));
const ConfigureSeo = lazy(() => import("../../searchengineoptimisationseo/src/ConfigureSeo.web"));
const ConfigureSeoEdit = lazy(() => import("../../searchengineoptimisationseo/src/ConfigureSeoEdit.web"));
const SeoLandingPage = lazy(() => import("../../searchengineoptimisationseo/src/SeoLandingPage.web"));
const SEOWithWrapper = lazy(() => import("../../searchengineoptimisationseo/src/Searchengineoptimisationseo.web"));
const LoyaltySystem = lazy(() => import("../../loyaltysystem/src/LoyaltySystem.web"));

import { Skeleton } from "@material-ui/lab";

const skeletonLoader = (
  <div >
    <Skeleton variant="rect" width="100%" height="100vh" />
  </div>
);

class WebRoutes extends Component {
  render() {
    return (
      <Suspense fallback={skeletonLoader}>
      <Switch>
        {/* <Route exact path="/" component={AdminEmailTemplates} /> */}
        <Route exact path="/Home" component={ItemAvailabilityLayout} />
        <Route exact path="/admin/login" component={EmailAccountLogin} />
        <Route
          exact
          path="/EmailAccountForgotPassword"
          component={EmailAccountForgotPassword}
        />
        <Route
          exact
          path="/AdminEmailTemplates"
          component={AdminEmailTemplates}
        />
        <Route
          //exact
          path="/AdminEmailTemplatesCreate/:id"
          component={AdminEmailTemplatesCreate}
        />
        <Route exact path="/AdminStaticPage" component={AdminStaticPage} />
        <Route
          exact
          path="/AdminStaticPageCreate/:id?"
          component={AdminStaticPageCreate}
        />
        <Route
          exact
          path="/AdminImageCatalogue"
          component={AdminImageCatalogue}
        />
        <Route
          exact
          path="/AdminImageCatalogueBulkUpload"
          component={AdminImageCatalogueBulkUpload}
        />
        <Route exact path="/AdminEditImage/:id" component={AdminEditImage} />
        <Route
          exact
          path="/AdminCustomerFeedback"
          component={AdminCustomerFeedback}
        />
        <Route
          exact
          path="/AdminCustomerFeedbackCreate/:id?"
          component={AdminCustomerFeedbackCreate}
        />
        <Route exact path="/AdminStoreDetails" component={AdminStoreDetails} />
        <Route exact path="/AdminTaxes" component={AdminTaxes} />
        <Route exact path="/AdminShipping" component={AdminShipping} />
        <Route exact path="/AdminPromoCode" component={AdminPromoCode} />
        <Route exact path="/ShareStoreTab" component={ShareStoreTab} />
        <Route exact path="/AdminMarketing" component={AdminMarketing} />
        <Route
          exact
          path="/AdminPromoCodeCreate/:id?"
          component={AdminPromoCodeCreate}
        />
        <Route
          exact
          path="/AdminPaymentIntegration"
          component={AdminPaymentIntegration}
        />
        <Route exact path="/AdminMobileApp" component={AdminMobileApp} />
        <Route
          exact
          path="/AdminNotifications"
          component={AdminNotifications}
        />
        <Route
          exact
          path="/AdminOtherIntegrations"
          component={OtherIntegrations}
        />
        <Route exact path="/AdminAccount" component={AdminAccount} />
        <Route exact path="/Variants" component={Variants} />
        <Route exact path="/InventoryManagement" component={InventoryManagement} />
        <Route exact path="/InventoryManagementLibrary" component={ItemsLibrary} />
        <Route exact path="/Categories" component={Categories} />
        <Route exact path="/Orders" component={OrderList} />
        <Route exact path="/OrdersPrint" component={OrderPrint} />
        <Route exact path="/Reviews" component={Reviews} />
        <Route exact path="/Customers" component={CustomerList} />
        <Route exact path="/InventoryManagementLibraryReview" component={ReviewItemsFromLibrary} />
        <Route exact path="/AdminBranding" component={AdminBranding} />
        <Route exact path="/AdminMarketing/AdminMarketing" component={MarketingSignupForm} />
        <Route exact path="/AdminMarketing/AdminMarketing/:id" component={MarketingSignupForm} />
        <Route exact path='/configure-seo' component={ConfigureSeo} />
        <Route exact path='/configure-seo/:id' component={ConfigureSeoEdit} />
        <Route exact path='/seo-checklist' component={SeoLandingPage} />
        <Route exact path="/sitemap" component={AdminSiteMap}/>
        <Route exact path="/AdminSEO" component={SEOWithWrapper} />
        <Route exact path="/AdminRewards" component={LoyaltySystem}/>
      </Switch>
      </Suspense>
    );
  }
}

export default WebRoutes;
// Customizable Area End
