import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
const EN = require('./en.json')
const AR = require('./ar.json')


 const resources = {
    en: {
      translation: EN
    },
    ar: {
      translation: AR
    }
  };


i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en', // Default language
    debug: true,
    interpolation: {
      escapeValue: false,
    },
    resources
  });
const i18Direction = i18n.dir();
const i18Language = i18n.language
export {i18Direction, i18Language}
export default i18n;