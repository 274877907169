// Customizable Area Start
export const Veg_icon = require("../assets/vegetarian-icon.png");
export const NonVeg_icon = require("../assets/non-vegetarian-icon.png");
export const Vegan_icon = require("../assets/vegan-icon.png");
export const Glutenfree_icon = require("../assets/gluten-free-icon.png");
export const Halal_icon = require("../assets/halal-icon.png");
export const Kosher_icon = require("../assets/kosher-icon.png");

export const Milk_icon = require("../assets/milk-icon.png");
export const Eggs_icon = require("../assets/eggs-icon.png");
export const Nuts_icon = require("../assets/nuts-icon.png");
export const Wheat_icon = require("../assets/wheat-icon.png");
export const Soy_icon = require("../assets/soy-icon.png");
export const Peanuts_icon = require("../assets/peanuts-icon.png");
export const Celery_icon = require("../assets/celery-icon.png");
export const Crustaceans_icon = require("../assets/crustaceans-icon.png");
export const Fish_icon = require("../assets/fish-icon.png");
export const Lupin_icon = require("../assets/lupin-icon.png");
export const Mollusc_icon = require("../assets/mollusc-icon.png");
export const Mustard_icon = require("../assets/mustard-icon.png");
export const Sesameseeds_icon = require("../assets/sesame-seeds-icon.png");
export const Sulphurdioxide_icon = require("../assets/sulphur-dioxide-icon.png");

export const Mild_icon = require("../assets/mild-icon.png");
export const Spicy_icon = require("../assets/spicy-icon.png");
export const Extraspicy_icon = require("../assets/extra-spicy-icon.png");

export const veg_icon = require("../assets/veg.png");
export const nonVeg_icon = require("../assets/nonVeg.png");
export const vegan_icon = require("../assets/vegan.png");
export const glutenfree_icon = require("../assets/gultenfree.png");
export const halal_icon = require("../assets/halal.png");
export const kosher_icon = require("../assets/kosher.png");

export const milk_icon = require("../assets/milk.png");
export const eggs_icon = require("../assets/eggs.png");
export const nuts_icon = require("../assets/nuts.png");
export const wheat_icon = require("../assets/wheat.png");
export const soy_icon = require("../assets/soy.png");
export const peanuts_icon = require("../assets/peanuts.png");
export const celery_icon = require("../assets/celery.png");
export const crustaceans_icon = require("../assets/crustaceans.png");
export const fish_icon = require("../assets/fish.png");
export const lupin_icon = require("../assets/lupin.png");
export const mollusc_icon = require("../assets/mollusc.png");
export const mustard_icon = require("../assets/mustard.png");
export const sesameseeds_icon = require("../assets/sesameseeds.png");
export const sulphurdioxide_icon = require("../assets/sulphurdioxide.png");

export const mild_icon = require("../assets/mild.png");
export const spicy_icon = require("../assets/spicy.png");
export const extraspicy_icon = require("../assets/extraspicy.png");

export const editIcon = require("../assets/editIcon.svg");
export const noProducts = require("../assets/menuitem.svg");
export const backIcon = require("../assets/backIcon.svg");
export const searchIcon = require("../assets/searchIcon.svg");
export const noCategories = require("../assets/nocategories.svg");
export const noVariants = require("../assets/noVariants.svg");
export const crossIcon = require("../assets/crossIcon.svg");

export const deleteIcon = require("../assets/deleteIcon.svg");
export const questionIcon = require("../assets/question.svg");

export const up_arrow = require("../assets/up_arrow.svg");
export const down_arrow = require("../assets/down_arrow.svg");
export const chevron_down = require("../assets/chevron_down.svg");
export const chevron_up = require("../assets/chevron_up.svg");
export const close = require("../assets/close.svg");
export const CloseIconPng = require("../assets/close.png");
export const successTick = require("../assets/successTick.png");
export const burger = require("../assets/burger.png");
export const pizza = require("../assets/pizza.png");
export const warning = require("../assets/warning.png");
export const failed = require("../assets/failed.png");
export const loadingBurger = require("../assets/loadingBurger.png");
export const importingScreenImage = require("../assets/image_110.svg");
export const addItems = require("../assets/add.svg");
export const deleteItemsIcon = require("../assets/delete.png");

// Customizable Area End
