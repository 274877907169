import React, { Component, Suspense } from 'react';
import { View } from 'react-native';
import { analytics } from '../../components/src/Firebase';

import WebRoutesGenerator from '../../components/src/NativeWebRouteWrapper';
import { ModalContainer } from 'react-router-modal';
import HomeScreen from '../../components/src/HomeScreen';
const InfoPage = React.lazy(() =>
  import('../../blocks/info-page/src/InfoPageBlock')
);
const AlertBlock = React.lazy(() =>
  import('../../blocks/alert/src/AlertBlock.web')
);
const BulkUploading = React.lazy(() =>
  import('../../blocks/bulkuploading/src/BulkUploading')
);
// import StripePayments from "../../blocks/stripepayments/src/StripePayments";
const SocialMediaAccountLogin = React.lazy(() =>
  import('../../blocks/social-media-account/src/SocialMediaAccountLogin')
);
const SocialMediaAccountRegistration = React.lazy(() =>
  import('../../blocks/social-media-account/src/SocialMediaAccountRegistration')
);
const Filteritems = React.lazy(() =>
  import('../../blocks/filteritems/src/Filteritems')
);
const Filteroptions = React.lazy(() =>
  import('../../blocks/filteritems/src/Filteroptions')
);
const ImportExportData = React.lazy(() =>
  import('../../blocks/importexportdata/src/ImportExportData')
);
const Websitebuilder = React.lazy(() =>
  import('../../blocks/websitebuilder/src/Websitebuilder')
);
const SocialMediaAccountLoginScreen = React.lazy(() =>
  import(
    '../../blocks/social-media-account-login/src/SocialMediaAccountLoginScreen'
  )
);
const ForgotPassword = React.lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPassword')
);
const ForgotPasswordOTP = React.lazy(() =>
  import('../../blocks/forgot-password/src/ForgotPasswordOTP')
);
const NewPassword = React.lazy(() =>
  import('../../blocks/forgot-password/src/NewPassword')
);
const DeliveryEstimator = React.lazy(() =>
  import('../../blocks/deliveryestimator/src/DeliveryEstimator')
);
const TermsConditions = React.lazy(() =>
  import('../../blocks/termsconditions/src/TermsConditions')
);
const TermsConditionsDetail = React.lazy(() =>
  import('../../blocks/termsconditions/src/TermsConditionsDetail')
);
const TermsConditionsUsers = React.lazy(() =>
  import('../../blocks/termsconditions/src/TermsConditionsUsers')
);
const Catalogue = React.lazy(() =>
  import('../../blocks/catalogue/src/Catalogue')
);
const SocialMediaAccountRegistrationScreen = React.lazy(() =>
  import(
    '../../blocks/social-media-account-registration/src/SocialMediaAccountRegistrationScreen'
  )
);
const Emailnotifications2 = React.lazy(() =>
  import('../../blocks/emailnotifications2/src/Emailnotifications2')
);
const OfflineBrowsing = React.lazy(() =>
  import('../../blocks/offlinebrowsing/src/OfflineBrowsing')
);
const CountryCodeSelector = React.lazy(() =>
  import('../../blocks/country-code-selector/src/CountryCodeSelector')
);
const UserProfileBasicBlock = React.lazy(() =>
  import('../../blocks/user-profile-basic/src/UserProfileBasicBlock')
);
const Storecontentmanagement2 = React.lazy(() =>
  import('../../blocks/storecontentmanagement2/src/Storecontentmanagement2')
);
const PhoneNumberInput = React.lazy(() =>
  import('../../blocks/mobile-account-registration/src/PhoneNumberInput')
);
const AdditionalDetailForm = React.lazy(() =>
  import('../../blocks/mobile-account-registration/src/AdditionalDetailForm')
);
const Pushnotifications = React.lazy(() =>
  import('../../blocks/pushnotifications/src/Pushnotifications')
);
const Contactus = React.lazy(() =>
  import('../../blocks/contactus/src/Contactus')
);
const AddContactus = React.lazy(() =>
  import('../../blocks/contactus/src/AddContactus')
);
const ApiIntegration = React.lazy(() =>
  import('../../blocks/apiintegration/src/ApiIntegration')
);
const Paymentadmin2 = React.lazy(() =>
  import('../../blocks/paymentadmin2/src/Paymentadmin2')
);
const AutomaticCheckoutCalculation = React.lazy(() =>
  import(
    '../../blocks/automaticcheckoutcalculation/src/AutomaticCheckoutCalculation'
  )
);
const EmailAccountRegistration = React.lazy(() =>
  import('../../blocks/email-account-registration/src/EmailAccountRegistration')
);
const Categoriessubcategories = React.lazy(() =>
  import('../../blocks/categoriessubcategories/src/Categoriessubcategories')
);
const Adminconsole2 = React.lazy(() =>
  import('../../blocks/adminconsole2/src/Adminconsole2')
);
const ShoppingCartOrders = React.lazy(() =>
  import('../../blocks/shoppingcart/src/ShoppingCartOrders')
);
const AddShoppingCartOrderItem = React.lazy(() =>
  import('../../blocks/shoppingcart/src/AddShoppingCartOrderItem')
);
const EmailAccountLoginBlock = React.lazy(() =>
  import('../../blocks/email-account-login/src/EmailAccountLoginBlock')
);
const NavigationMenu = React.lazy(() =>
  import('../../blocks/navigationmenu/src/NavigationMenu')
);
const LandingPage = React.lazy(() =>
  import('../../blocks/landingpage/src/LandingPage')
);
const AdvancedSearch = React.lazy(() =>
  import('../../blocks/advancedsearch/src/AdvancedSearch')
);
const EducationalUserProfile = React.lazy(() =>
  import('../../blocks/educational-user-profile/src/EducationalUserProfile')
);
const RequestManagement = React.lazy(() =>
  import('../../blocks/requestmanagement/src/RequestManagement')
);
const Rolesandpermissions2 = React.lazy(() =>
  import('../../blocks/rolesandpermissions2/src/Rolesandpermissions2')
);
const OrderManagement = React.lazy(() =>
  import('../../blocks/ordermanagement/src/OrderManagement')
);
const OTPInputAuth = React.lazy(() =>
  import('../../blocks/otp-input-confirmation/src/OTPInputAuth')
);
const LandingPageScreen = React.lazy(() =>
  import('../../blocks/landingpage/src/LandingPage.web')
);
const Contentmanagement1 = React.lazy(() =>
  import('../../blocks/contentmanagement1/src/Contentmanagement1')
);
const Adminconsole = React.lazy(() =>
  import('../../blocks/adminconsole/src/Adminconsole')
);
const Signuplogin = React.lazy(() =>
  import('../../blocks/signuplogin/src/Signuplogin')
);
const Termsandconditions = React.lazy(() =>
  import('../../blocks/termsandconditions/src/Termsandconditions')
);
const Ordermanagement1 = React.lazy(() =>
  import('../../blocks/ordermanagement1/src/Ordermanagement1')
);
const Rolesandpermissions = React.lazy(() =>
  import('../../blocks/rolesandpermissions/src/Rolesandpermissions')
);
const Dashboard4 = React.lazy(() =>
  import('../../blocks/dashboard4/src/Dashboard4')
);
const Paymentadmin = React.lazy(() =>
  import('../../blocks/paymentadmin/src/Paymentadmin')
);
const Payments = React.lazy(() => import('../../blocks/payments/src/Payments'));

const MenuItemDetailsPage = React.lazy(() =>
  import('../../blocks/landingpage/src/MenuItemDetailsPage.web')
);
const AdminLogin = React.lazy(() =>
  import('../../blocks/adminconsole/src/AdminLogin.web')
);
const ChangePassword = React.lazy(() =>
  import('../../blocks/adminconsole/src/ChangePassword.web')
);
const ForgetPassword = React.lazy(() =>
  import('../../blocks/adminconsole/src/ForgetPassword.web')
);
const StaticPages = React.lazy(() =>
  import('../../blocks/landingpage/src/StaticPages.web')
);
const OrderConfirmationSummary = React.lazy(() =>
  import('../../blocks/shoppingcart/src/OrderConfirmationSummary.web')
);
const CartOrders = React.lazy(() =>
  import('../../blocks/shoppingcart/src/CartOrders.web')
);
const WebsiteBuilderTheme = React.lazy(() =>
  import('../../blocks/storecontentmanagement2/src/WebsiteBuilderTheme')
);
const StripePaymentsHome = React.lazy(() =>
  import('../../blocks/stripepayments/src/StripePaymentsHome.web')
);
const RecentOrders = React.lazy(() =>
  import('../../blocks/user-profile-basic/src/UserProfileBasic.web')
);
const CustomerReviewRating = React.lazy(() =>
  import('../../blocks/adminemailtemplates/src/CustomerReviewRating.web')
);
const JSSRTL = React.lazy(() => import('../../components/src/JSSRTL.web'));
const ExpiryPage = React.lazy(() => import('../../components/src/ExpiryOrderPage.web'));
const CustomerRewards = React.lazy(() => import('../../blocks/loyaltysystem/src/RewardListing.web'));
const CustomerUserProfile = React.lazy(() => import('../../blocks/user-profile-basic/src/CustomerUserProfile.web'));
const CustomerAddress = React.lazy(() => import('../../blocks/user-profile-basic/src/CustomerAddresses.web'));
import { Skeleton } from '@material-ui/lab';
import { sendToDataLayer } from '../../components/src/GoogleTagManager';

const routeMap = {
  ExpiryOrder: {
    component: ExpiryPage,
    path: '/InvalidReviewLink',
  },
  Contentmanagement1: {
    component: Contentmanagement1,
    path: '/Contentmanagement1',
  },
  CustomerReviewRating: {
    component: CustomerReviewRating,
    path: '/CustomerReviewRating',
  },
  OrderConfirmationSummary: {
    component: OrderConfirmationSummary,
    path: '/orderconfirmation',
  },
  Adminconsole: {
    component: Adminconsole,
    path: '/Adminconsole',
  },
  Signuplogin: {
    component: Signuplogin,
    path: '/Signuplogin',
  },
  Termsandconditions: {
    component: Termsandconditions,
    path: '/Termsandconditions',
  },
  Ordermanagement1: {
    component: Ordermanagement1,
    path: '/Ordermanagement1',
  },
  Rolesandpermissions: {
    component: Rolesandpermissions,
    path: '/Rolesandpermissions',
  },
  Dashboard4: {
    component: Dashboard4,
    path: '/Dashboard4',
  },
  Paymentadmin: {
    component: Paymentadmin,
    path: '/Paymentadmin',
  },
  Payments: {
    component: Payments,
    path: '/Payments',
  },

  BulkUploading: {
    component: BulkUploading,
    path: '/BulkUploading',
  },
  StripePaymentsHome: {
    component: StripePaymentsHome,
    path: '/StripePayments',
    exact: true,
  },
  SocialMediaAccountLogin: {
    component: SocialMediaAccountLogin,
    path: '/SocialMediaAccountLogin',
  },
  SocialMediaAccountRegistration: {
    component: SocialMediaAccountRegistration,
    path: '/SocialMediaAccountRegistration',
  },
  Filteritems: {
    component: Filteritems,
    path: '/Filteritems',
  },
  Filteroptions: {
    component: Filteroptions,
    path: '/Filteroptions',
  },
  ImportExportData: {
    component: ImportExportData,
    path: '/ImportExportData',
  },
  Websitebuilder: {
    component: Websitebuilder,
    path: '/Websitebuilder',
  },
  SocialMediaAccountLoginScreen: {
    component: SocialMediaAccountLoginScreen,
    path: '/SocialMediaAccountLoginScreen',
  },
  ForgotPassword: {
    component: ForgotPassword,
    path: '/ForgotPassword',
  },
  ForgotPasswordOTP: {
    component: ForgotPasswordOTP,
    path: '/ForgotPasswordOTP',
  },
  NewPassword: {
    component: NewPassword,
    path: '/NewPassword',
  },
  DeliveryEstimator: {
    component: DeliveryEstimator,
    path: '/DeliveryEstimator',
  },
  TermsConditions: {
    component: TermsConditions,
    path: '/TermsConditions',
  },
  TermsConditionsDetail: {
    component: TermsConditionsDetail,
    path: '/TermsConditionsDetail',
  },
  TermsConditionsUsers: {
    component: TermsConditionsUsers,
    path: '/TermsConditionsUsers',
  },
  Catalogue: {
    component: Catalogue,
    path: '/Catalogue',
  },
  SocialMediaAccountRegistrationScreen: {
    component: SocialMediaAccountRegistrationScreen,
    path: '/SocialMediaAccountRegistrationScreen',
  },
  Emailnotifications2: {
    component: Emailnotifications2,
    path: '/Emailnotifications2',
  },
  OfflineBrowsing: {
    component: OfflineBrowsing,
    path: '/OfflineBrowsing',
  },
  CountryCodeSelector: {
    component: CountryCodeSelector,
    path: '/CountryCodeSelector',
  },
  UserProfileBasicBlock: {
    component: UserProfileBasicBlock,
    path: '/UserProfileBasicBlock',
  },
  RecentOrders: {
    component: RecentOrders,
    path: '/RecentOrders',
  },
  Storecontentmanagement2: {
    component: Storecontentmanagement2,
    path: '/Storecontentmanagement2',
  },
  PhoneNumberInput: {
    component: PhoneNumberInput,
    path: '/PhoneNumberInput',
  },
  AdditionalDetailForm: {
    component: AdditionalDetailForm,
    path: '/AdditionalDetailForm',
  },
  Pushnotifications: {
    component: Pushnotifications,
    path: '/Pushnotifications',
  },
  Contactus: {
    component: Contactus,
    path: '/Contactus',
  },
  AddContactus: {
    component: AddContactus,
    path: '/AddContactus',
  },
  ApiIntegration: {
    component: ApiIntegration,
    path: '/ApiIntegration',
  },
  Paymentadmin2: {
    component: Paymentadmin2,
    path: '/Paymentadmin2',
  },
  AutomaticCheckoutCalculation: {
    component: AutomaticCheckoutCalculation,
    path: '/AutomaticCheckoutCalculation',
  },
  EmailAccountRegistration: {
    component: EmailAccountRegistration,
    path: '/EmailAccountRegistration',
  },
  Categoriessubcategories: {
    component: Categoriessubcategories,
    path: '/Categoriessubcategories',
  },
  Adminconsole2: {
    component: Adminconsole2,
    path: '/Adminconsole2',
  },
  ShoppingCartOrders: {
    component: ShoppingCartOrders,
    path: '/ShoppingCartOrders',
  },
  AddShoppingCartOrderItem: {
    component: AddShoppingCartOrderItem,
    path: '/AddShoppingCartOrderItem',
  },
  EmailAccountLoginBlock: {
    component: EmailAccountLoginBlock,
    path: '/EmailAccountLoginBlock',
  },
  NavigationMenu: {
    component: NavigationMenu,
    path: '/NavigationMenu',
  },
  AdvancedSearch: {
    component: AdvancedSearch,
    path: '/AdvancedSearch',
  },
  EducationalUserProfile: {
    component: EducationalUserProfile,
    path: '/EducationalUserProfile',
  },
  RequestManagement: {
    component: RequestManagement,
    path: '/RequestManagement',
  },
  Rolesandpermissions2: {
    component: Rolesandpermissions2,
    path: '/Rolesandpermissions2',
  },
  OrderManagement: {
    component: OrderManagement,
    path: '/OrderManagement',
  },
  OTPInputAuth: {
    component: OTPInputAuth,
    path: '/OTPInputAuth',
  },
  LandingPage: {
    component: LandingPage,
    path: '/LandingPage',
  },

  HomeScreen: {
    component: HomeScreen,
    path: '/HomeScreen',
    exact: true,
  },
  Home: {
    component: require('../../blocks/ss-cms-common-router/src/WebRoutes')
      .default,
    path: '*',
    exact: true,
  },

  InfoPage: {
    component: InfoPage,
    path: '/InfoPage',
  },

  LandingPageScreen: {
    component: LandingPageScreen,
    path: '/',
    exact: true,
  },
  StaticPages: {
    component: StaticPages,
    path: '/help-center',
    exact: true,
  },
  CustomerRewards:{
    component: CustomerRewards,
    path: '/CustomerRewards',
    exact: true,
  },
  CustomerUserProfile:{
    component: CustomerUserProfile,
    path: '/CustomerUserProfile',
    exact: true,
  },
  CustomerAddress:{
    component: CustomerAddress,
    path: '/CustomerAddress',
    exact: true,
  },
  CartOrders: {
    component: CartOrders,
    path: '/CartOrders',
    exact: true,
  },
  StaticPageDetail: {
    component: StaticPages,
    path: '/help-center/:id',
    exact: true,
  },

  MenuItemDetailsPage: {
    component: MenuItemDetailsPage,
    path: '/MenuItemDetails',
  },
  AdminLogin: {
    component: AdminLogin,
    path: '/AdminLogin',
  },

  ChangePassword: {
    component: ChangePassword,
    path: '/ChangePassword',
  },
  ForgetPassword: {
    component: ForgetPassword,
    path: '/ForgetPassword',
  },
  AlertWeb: {
    component: AlertBlock,
    path: '/AlertWeb',
    modal: true,
  },
};

const skeletonLoader = (
  <div>
    <Skeleton variant="rect" width="100%" height="100vh" />
  </div>
);

class App extends Component {
  handleNavigationToOrderConfirm = (path) => {
    const to = new Message(getName(MessageEnum.NavigationMessage));
    to.addData(getName(MessageEnum.NavigationTargetMessage), path);
    to.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
    runEngine.sendMessage(to.messageId, to);
  };

  render() {
    analytics.logEvent('APP_Loaded');

    //Google tag manager
    sendToDataLayer({
      event: 'event',
      eventProps: {
        value: 'App initialized',
      },
    });

    const url = window.location.href;
    if (url.includes('bx_block_stripe_integration/payment_methods/success')) {
      this.handleNavigationToOrderConfirm('OrderConfirmationSummary');
    }
    if (url.includes('bx_block_stripe_integration/payment_methods/cancel')) {
      this.handleNavigationToOrderConfirm('CartOrders');
    }

    return (
      <Suspense fallback={skeletonLoader}>
        <JSSRTL>
          <View style={{ height: '100vh', width: '100vw' }}>
            {/* <TopNav /> */}
            <WebsiteBuilderTheme />
            {WebRoutesGenerator({ routeMap })}
            <ModalContainer />
          </View>
        </JSSRTL>
      </Suspense>
    );
  }
}

export default App;
