// @ts-nocheck
import React, { Component } from "react";
import {DesktopToastContext} from "../Snackbar/HeaderToastContext";
export type withToastProps = {
  showToast : (obj : any) => void
}
function withToast(OriginalComponent) {
  return (props) =>  {
    const { openToast } = React.useContext(DesktopToastContext);
    const showToast = (options) => {
          openToast?.({ ...options });
          // window.alert(options.message)
      }
    return (
      <>
        <OriginalComponent {...props} showToast={showToast}/>
      </>
    );
  };
}
export default withToast;